import { Avatar, Typography } from 'antd';
import React, { useContext, useEffect, useRef, useState } from "react";
import { LoopCircleLoading } from 'react-loadingg';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Styled from 'styled-components';
import { LandingPageContext } from '../../context/LandingPageContext';
import BR1 from '../../static/landing/Ecommerce/brThumbnail1.jpg';
import BR10 from '../../static/landing/Ecommerce/brThumbnail10.jpg';
import BR11 from '../../static/landing/Ecommerce/brThumbnail11.jpg';
import BR13 from '../../static/landing/Ecommerce/brThumbnail12.jpg';
import BR12 from '../../static/landing/Ecommerce/brThumbnail13.jpg';
import BR14 from '../../static/landing/Ecommerce/brThumbnail14.jpg';
import BR2 from '../../static/landing/Ecommerce/brThumbnail2.jpg';
import BR3 from '../../static/landing/Ecommerce/brThumbnail3.jpg';
import BR4 from '../../static/landing/Ecommerce/brThumbnail4.jpg';
import BR5 from '../../static/landing/Ecommerce/brThumbnail5.jpg';
import BR6 from '../../static/landing/Ecommerce/brThumbnail6.jpg';
import BR7 from '../../static/landing/Ecommerce/brThumbnail7.jpg';
import BR8 from '../../static/landing/Ecommerce/brThumbnail8.jpg';
import BR9 from '../../static/landing/Ecommerce/brThumbnail9.jpg';
import BRVideo1 from '../../static/landing/Ecommerce/brVideo1.mp4';
import BRVideo2 from '../../static/landing/Ecommerce/brVideo2.mp4';
import BRVideo3 from '../../static/landing/Ecommerce/brVideo3.mp4';
import BRVideo4 from '../../static/landing/Ecommerce/brVideo4.mp4';
import BRVideo5 from '../../static/landing/Ecommerce/brVideo5.mp4';
import BRVideo6 from '../../static/landing/Ecommerce/brVideo6.mp4';
import BRVideo7 from '../../static/landing/Ecommerce/brVideo7.mp4';
import BRVideo8 from '../../static/landing/Ecommerce/brVideo8.mp4';
import EC1 from '../../static/landing/Ecommerce/ec1.jpg';
import EC10 from '../../static/landing/Ecommerce/ec10.jpg';
import EC11 from '../../static/landing/Ecommerce/ec11.jpg';
import EC13 from '../../static/landing/Ecommerce/ec12.jpg';
import EC12 from '../../static/landing/Ecommerce/ec13.jpg';
import EC14 from '../../static/landing/Ecommerce/ec14.jpg';
import EC15 from '../../static/landing/Ecommerce/ec15.jpg';
import EC16 from '../../static/landing/Ecommerce/ec16.jpg';
import EC17 from '../../static/landing/Ecommerce/ec17.jpg';
import EC2 from '../../static/landing/Ecommerce/ec2.jpg';
import EC3 from '../../static/landing/Ecommerce/ec3.jpg';
import EC4 from '../../static/landing/Ecommerce/ec4.jpg';
import EC5 from '../../static/landing/Ecommerce/ec5.jpg';
import EC6 from '../../static/landing/Ecommerce/ec6.jpg';
import EC7 from '../../static/landing/Ecommerce/ec7.jpg';
import EC8 from '../../static/landing/Ecommerce/ec8.jpg';
import EC9 from '../../static/landing/Ecommerce/ec9.jpg';
import ECVideo1 from '../../static/landing/Ecommerce/ecVideo1.mp4';
import ECVideo2 from '../../static/landing/Ecommerce/ecVideo2.mp4';
import ECVideo3 from '../../static/landing/Ecommerce/ecVideo3.mp4';
import ECVideo4 from '../../static/landing/Ecommerce/ecVideo4.mp4';
import ECVideo5 from '../../static/landing/Ecommerce/ecVideo5.mp4';
import ECVideo6 from '../../static/landing/Ecommerce/ecVideo6.mp4';
import ECVideo7 from '../../static/landing/Ecommerce/ecVideo7.mp4';
import ECVideo8 from '../../static/landing/Ecommerce/ecVideo8.mp4';
import Game1 from '../../static/landing/Ecommerce/gameThumbnail1.jpg';
import Game10 from '../../static/landing/Ecommerce/gameThumbnail10.jpg';
import Game11 from '../../static/landing/Ecommerce/gameThumbnail11.jpg';
import Game13 from '../../static/landing/Ecommerce/gameThumbnail12.jpg';
import Game12 from '../../static/landing/Ecommerce/gameThumbnail13.jpg';
import Game14 from '../../static/landing/Ecommerce/gameThumbnail14.jpg';
import Game2 from '../../static/landing/Ecommerce/gameThumbnail2.jpg';
import Game3 from '../../static/landing/Ecommerce/gameThumbnail3.jpg';
import Game4 from '../../static/landing/Ecommerce/gameThumbnail4.jpg';
import Game5 from '../../static/landing/Ecommerce/gameThumbnail5.jpg';
import Game6 from '../../static/landing/Ecommerce/gameThumbnail6.jpg';
import Game7 from '../../static/landing/Ecommerce/gameThumbnail7.jpg';
import Game8 from '../../static/landing/Ecommerce/gameThumbnail8.jpg';
import Game9 from '../../static/landing/Ecommerce/gameThumbnail9.jpg';
import GAMEVideo1 from '../../static/landing/Ecommerce/gameVideo1.mp4';
import GAMEVideo2 from '../../static/landing/Ecommerce/gameVideo2.mp4';
import GAMEVideo3 from '../../static/landing/Ecommerce/gameVideo3.mp4';
import GAMEVideo4 from '../../static/landing/Ecommerce/gameVideo4.mp4';
import GAMEVideo5 from '../../static/landing/Ecommerce/gameVideo5.mp4';
import GAMEVideo6 from '../../static/landing/Ecommerce/gameVideo6.mp4';
import GAMEVideo7 from '../../static/landing/Ecommerce/gameVideo7.mp4';
import GAMEVideo8 from '../../static/landing/Ecommerce/gameVideo8.mp4';
import people01 from '../../static/landing/people01.png';
import people02 from '../../static/landing/people02.png';
import people03 from '../../static/landing/people03.png';
import people04 from '../../static/landing/people04.png';
import people05 from '../../static/landing/people05.png';
import people06 from '../../static/landing/people06.png';
import people07 from '../../static/landing/people07.png';

const EC = [
    {   
        "img" : EC1,
    },{
        "img" : EC2,
    },{
        "img": EC3,
    },{ 
        "img": EC4,
    },{
        "img": EC5,
    },{
        "img": EC6,
    },{
        "img": EC7,
    },{ 
        "img": EC8,
    },{ 
        "img": EC9,
    },{ 
        "img": EC10, 
    },{
        "img": EC11,
    },{
        "img": EC12,
    },{
        "img": EC13,
    },{
        "img": EC14,
    },{
        "img": EC15,
    },{
        "img": EC16,
    },{
        "img": EC17,
    }]
const BR = [
    {   
        "img" : BR1,
    },{
        "img" : BR2,
    },{
        "img" : BR3,
    },{ 
        "img" : BR4,
    },{
        "img" : BR5,
    },{
        "img" : BR6,
    },{
        "img" : BR7,
    },{ 
        "img" : BR8,
    },{ 
        "img" : BR9,
    },{ 
        "img" : BR10, 
    },{
        "img" : BR11,
    },{
        "img" : BR12,
    },{
        "img" : BR13,
    },{
        "img" : BR14,
    }]
const GAME = [
    {   
        "img" : Game1,
    },{
        "img" : Game2,
    },{
        "img" : Game3,
    },{ 
        "img" : Game4,
    },{
        "img" : Game5,
    },{
        "img" : Game6,
    },{
        "img" : Game7,
    },{ 
        "img" : Game8,
    },{ 
        "img" : Game9,
    },{ 
        "img" : Game10, 
    },{
        "img" : Game11,
    },{
        "img" : Game12,
    },{
        "img" : Game13,
    },{
        "img" : Game14,
    }]
const ECVideo = [{   
        "img" : ECVideo1,
    },{
        "img" : ECVideo2,
    },{
        "img" : ECVideo3,
    },{ 
        "img" : ECVideo4,
    },{
        "img" : ECVideo5,
    },{
        "img" : ECVideo6,
    },{
        "img" : ECVideo7,
    },{
        "img" : ECVideo8,
    }]
const BRVideo = [
    {   
            "img" : BRVideo1,
    },{
            "img" : BRVideo2,
    },{
            "img" : BRVideo3,
    },{ 
            "img" : BRVideo4,
    },{
            "img" : BRVideo5,
    },{
        "img" : BRVideo6,
    },{
        "img" : BRVideo7,
    },{
        "img" : BRVideo8,
    }]
const GAMEVideo = [{   
        "img" : GAMEVideo1,
    },{
        "img" : GAMEVideo2,
    },{
        "img" : GAMEVideo3,
    },{ 
        "img" : GAMEVideo4,
    },{
        "img" : GAMEVideo5,
    },{
        "img" : GAMEVideo6,
    },{
        "img" : GAMEVideo7,
    },{
        "img" : GAMEVideo8,
    }]
const { Title } = Typography;
const StyledCarousel = Styled(Carousel)`
    .carousel .slide {
        background: rgb(255 255 255 / 0%);
        min-height: 400px;
        padding-bottom: 60px;
        p {
            text-align: justify;
        }
    }
    h3 {
        margin-top: 2rem;
    }
    h4.ant-typography {
        font-size: 24px;
        color: rgb(108, 117, 125);
        margin-top: 0;
    }
    .carousel .slider-wrapper {
        width: 90%;
    }
    .carousel .control-dots .dot {
        width: 40px;
        background: #00a1de;
        border-radius: 0;
        box-shadow: 1px 1px 2px rgb(0 0 0 / 0%);
    }
    .carousel {
        &.carousel-slider .control-arrow {
            @media (max-width: 767px) {
                padding: 0px;
            }
            &:hover {
                background: rgba(0,0,0,0);
            }
        }
        .control-prev.control-arrow {
            left: -5px;
            &:before {
                border-right: 8px solid #a8a9aa;
            }
        }
        .control-next.control-arrow{ 
            right: -5px;
            &:before {
                border-left: 8px solid #a8a9aa;
            }
        }
    }
`
const StyledModalCarousel = Styled(Carousel)`
    .carousel .slide {
        background: rgb(255 255 255 / 0%);
    }
    h3 {
        margin-top: 2rem;
    }
    h4.ant-typography {
        font-size: 24px;
        color: rgb(108, 117, 125);
        margin-top: 0;
    }
    .carousel .control-dots .dot {
        width: 40px;
        background: #00a1de;
        border-radius: 0;
        box-shadow: 1px 1px 2px rgb(0 0 0 / 0%);
    }
    .carousel {
        &.carousel-slider {
            margin: auto;
        }
        .slider-wrapper.axis-horizontal .slider {
            align-items: center;
        }
        .control-prev.control-arrow {
            left: -5px;
        }
        .control-next.control-arrow {
            right: -5px;
        }
        &.carousel-slider .control-arrow {
            opacity: 1;
            padding: 0px;
        }
        &.carousel-slider .control-arrow:hover {
            background: rgba(0,0,0,0);
        }
        .control-prev.control-arrow:before {
            border-right: 8px solid #0d2134;
        }
        .control-next.control-arrow:before {
            border-left: 8px solid #0d2134;
        }
    }
`
const StyledViedo = Styled(ReactPlayer)`
    position: relative;
    margin: auto;
    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    video {
        object-fit: contain;
        z-index:-1;
    }
`

const ImageCarousel = ({select, onChange, species}) => {
    const [imgeWidth, setImgWidth] = useState('500px'); 
    useEffect(() => {
        if (window.innerWidth >= 768) {
            setImgWidth('500px');
        }
        if (window.innerWidth < 768) {
            setImgWidth('245px');
        }
    },[])
    switch (species) {
        case 'GAME':
            return (
            <StyledModalCarousel width={imgeWidth} showArrows={false} onChange={onChange} selectedItem={select} autoPlay={false} infiniteLoop={false} showStatus={false} showThumbs={false} showIndicators={false}>
                {GAME.map((item, index) => 
                    <div key={index}>
                        <img src={item.img} width="100%" alt="game" />
                    </div>
                )}
            </StyledModalCarousel>
            )
        case 'EC':
            return (
            <StyledModalCarousel width={imgeWidth} showArrows={false} selectedItem={select} onChange={onChange} autoPlay={false} infiniteLoop={false} showStatus={false} showThumbs={false} showIndicators={false}>
                {EC.map((item, index) => 
                    <div key={index}>
                        <img src={item.img} width="100%" alt="EC" />
                    </div>
                )}
            </StyledModalCarousel>
            )     
        case 'BR':
            return (
            <StyledModalCarousel width={imgeWidth} showArrows={false} onChange={onChange} selectedItem={select} autoPlay={false} infiniteLoop={false} showStatus={false} showThumbs={false} showIndicators={false}>
                {BR.map((item, index) => 
                    <div key={index}>
                        <img src={item.img} width="100%" alt="BR" />
                    </div>
                )}
            </StyledModalCarousel>
            )       
        default:
            return (
                <></>
            )
    }
}

const VideoArray = ({currentVideo, index}) => {
    const [deviceWidth, setDeviceWidth] = useState('100%'); 
    const [deviceHeight, setDeviceHeight] = useState('430px');
    const [target,setTarget] = useState(null);
    const getLoadedRef = useRef();
    const { onLoadding, isVideoLoad } = useContext(LandingPageContext);

    const onProgress = () => {
        if (getLoadedRef.current.getDuration() === null) {
            onLoadding(true)
        }
    };
    const onDuration = () => {
        if(getLoadedRef.current.getDuration() != null) {
            onLoadding(false)
        }
    }
    useEffect(() => {
        switch(currentVideo) {
            case "ECVideo":
                 setTarget(ECVideo[index].img);
            break;
            case "BRVideo":
                setTarget(BRVideo[index].img);
            break;
            case "GAMEVideo": 
                setTarget(GAMEVideo[index].img);
            break;
            default:
                onLoadding(true);
            break;
        }
        if (window.innerWidth >= 768) {
            setDeviceWidth('100%');
            setDeviceHeight('430px');
        }
        if (window.innerWidth < 768) {
            setDeviceWidth('245px');
            setDeviceHeight('180px');
        }
    },[target])
    return (
            <>  
                {isVideoLoad ? (<div style={{ width: '240px', margin: 'auto' }}><LoopCircleLoading /></div>) : (<></>) }
                <StyledViedo
                    loop
                    muted
                    playing
                    playsinline
                    controls={false}
                    className={isVideoLoad ? ('react-player disVisable') : ('react-player') }
                    url={target}
                    width={deviceWidth}
                    height={deviceHeight}
                    ref={getLoadedRef}
                    onProgress={() => onProgress()}
                    onDuration={() => onDuration()}
                />
            </>
    )
}

const RecommandCarousel = () => {
    return (
        <StyledCarousel autoPlay infiniteLoop showStatus={false} showThumbs={false} >
        <div>
            <Avatar size={125} src={people07} />
            <Title level={3}>數位廣告行銷主管 - 廖心慧</Title>
            <Title level={4}>數位廣告代理商</Title>
            <p>系統整體使用下來的體驗是好的，一開始在註冊付款都很簡單快速，使用時覺得介面操作很直覺，不會讓人找不到功能在哪，最讓我喜歡的功能是 AdHero 的市場圖片，裡面可以讓我找到很多別人的設計風格，為團隊免去每次都必須要花很多時間發想的功夫，參考市場多樣的素材內容，我也更能與美術人員溝通去嘗試各種不同風格的製作可能，總體得到的廣告成效也比平均還要好，現在變成每次製作素材都一定會打開AdHero來參考；其他的功能也都很實用，AdHero真的幫助我們在廣告操作上很多事項。</p>
        </div>
        <div>
            <Avatar size={125} src={people03} />
            <Title level={3}>多媒體設計師 - 王伊婷</Title>
            <Title level={4}>流行美妝零售公司</Title>
            <p>AdHero就是一個全面的影片範例資料庫，底下又細分很多的產業，省下我尋找影片範例的時間，也能從中獲取更多元的靈感。雖說我已經累積不少製作影片的經驗，但影片分析功能可以提供我優化素材建議，讓我快速了解到哪個片段情緒沉悶、文字資訊是否適中等等的資訊。總歸來說，AdHero的市場素材提升我工作的效能，也讓我製作出更不一樣的素材，非常值得參考。</p>
        </div>
        <div>
            <Avatar size={125} src={people06} />
            <Title level={3}>廣告AE - 張岑緯</Title>
            <Title level={4}>遊戲代理公司</Title>
            <p>蒐集情報時偶然發現AdHero，試用後覺得每天都可以看到新的遊戲廣告圖片真的很不錯。因為投廣告的時候素材要一直換，這個工具可以方便觀察競品的素材，也讓我發想新素材的時候有參考依據。</p>
        </div>
        <div>
            <Avatar size={125} src={people02} />
            <Title level={3}>資深廣告業務 - 葉浩呈</Title>
            <Title level={4}>數位廣告代理商</Title>
            <p>在我們這行中，常會接觸到不同種類的產品，每當遇到新產品類型的客戶都會讓我捏一把冷汗，因為要花非常多時間了解該領域的優劣勢，深怕一不小心就會掉單。偶然在同業推薦下使用了AdHero，除了讓我隨時掌握市場資訊，面對客戶時，也能馬上秀出大量的競品素材給客戶看，讓客戶了解到我們是隨時觀察並更新市場趨勢的專業代理商，進而增加提案成功率，推薦給想當Top Sales的你！</p>
        </div>
        <div>
            <Avatar size={125} src={people05} />
            <Title level={3}>視覺設計師 - 張欣儀</Title>
            <Title level={4}>電商平台</Title>
            <p>我是網購平台的視覺設計師，每天都有不同的新品上市和每月主題活動要設計，都要絞盡腦汁發想各Banner的設計風格，除了要凸顯產品本身的特性之外，還要求新求變並結合時事跟上趨勢，真的很困難。本來想說試用看看反正七天內可以退款，結果一用就一直用到現在...雖然3000元有點貴但是還蠻值得的，現在我每天上班第一件事情，就是上AdHero查看其他家的素材和主題設計，除增加設計方向外，我也會觀察人家素材的分析結果，留意他人素材不足的地方，自我加強，讓我的設計更完美更吸引消費者。</p>
        </div>
        <div>
            <Avatar size={125} src={people01} />
            <Title level={3}>社群小編 - 陳政豪</Title>
            <Title level={4}>電腦週邊零售公司</Title>
            <p>以往發文都要絞盡腦汁想文案，苦惱圖片要怎麼設計才能增加轉貼數，網路找素材的時候偶然發現了AdHero這種市場素材懶人包，裡面有超多以前沒有想過的素材設計方式，省下了很多發想素材的時間。新貼文的讚數跟留言數都有增加的趨勢，粉絲數也成長了不少，每篇貼文也至少被轉發數十次以上。我很推薦同為社群小編的大家使用AdHero！</p>
        </div>
        <div>
            <Avatar size={125} src={people04} />
            <Title level={3}>平面設計師 - 王信宏</Title>
            <Title level={4}>整合行銷公司</Title>
            <p>年初剛加入電商廣告設計行列菜菜的我，很幸運的在網路上發現 AdHero，對於裡面給的素材風險建議，本來是抱著半信半疑的想法照做，但後來優化師跟我說廣告成效有提升，參考top100做出的banner也讓主管稱讚風格多變，讓我對於工具的依賴度加深，一個月 3000元的費用CP 值很高！</p>
        </div>
      </StyledCarousel>
    )
}

export { RecommandCarousel, ImageCarousel, VideoArray };

