import React from 'react';
import { ReactSVG } from 'react-svg';
import Korea from '../../static/country/flag.svg';
import Taiwan from '../../static/country/taiwan.svg';
import Usa from '../../static/country/usa-today.svg';
import Japan from '../../static/country/japan.svg';

const KoreaSvg = () => {
    return (
        <ReactSVG src={Korea}   beforeInjection={svg => {
            svg.setAttribute('style', 'width: 20px; margin: 3px 5px')
          }} />
    )
}

const UsaSvg = () => {
    return (
        <ReactSVG src={Usa}   beforeInjection={svg => {
            svg.setAttribute('style', 'width: 20px; margin: 3px 5px')
          }} />        
    )
}

const TaiwanSvg = () => {
    return (
        <ReactSVG src={Taiwan}   beforeInjection={svg => {
            svg.setAttribute('style', 'width: 20px; margin: 6px 5px 3px 5px')
          }} />        
    )
}

const JapanSvg = () => {
    return (
        <ReactSVG src={Japan}   beforeInjection={svg => {
            svg.setAttribute('style', 'width: 20px; margin: 3px 5px')
          }} />        
    )
}

export { KoreaSvg, UsaSvg, TaiwanSvg, JapanSvg };



