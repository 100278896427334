import React from 'react';
import { ReactSVG } from 'react-svg';
import LimitSale from '../../static/price/limitSale.svg';


const LimitSaleSvg = () => {
    return (
        <>
        <ReactSVG src={LimitSale}  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />        
        </>
    )
}

export { LimitSaleSvg };

