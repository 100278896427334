import { Button, Card, Col, Modal, Row, Typography, Tabs } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Styled from "styled-components";
import { LimitSaleSvg } from './Svg/Price';
import DiscountSVG from '../static/efun/discount.svg';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const StyledLimitSale = Styled.div`
  position: fixed;
  right: 15px;
  top: 65px;
  z-index: 1000;
  cursor: pointer;
  @media (max-width: 767px) {
    right: 5px;
    top: 50px;
  }
    &:before {
      content: attr(data-content);
      bottom: -70px;
      font-weight: 500;
      position: absolute;
      width: 100%;
      height: 70px;
      font-size: 15px;
      color: #00a1de;
      text-align: center;
      @media (max-width: 767px) {
        bottom: -45px;
        font-weight: 100;
        left: 1px;
        writing-mode: vertical-lr;
        display: none;
      }      
    }
  svg {
    @media (max-width: 767px) {
      width: 30px;
    }
  }
`
const StyledModal = Styled(Modal)`
@media (max-width: 767px) {
  .ant-modal-body {
    padding: 10px;
  }
}
`
const StyledTitle = Styled.div`
    text-align: center;
    padding: 70px 0px 20px 0px; 
    @media (max-width: 768px) {
        padding: 60px 0px 60px 0px;
    }
    h3.ant-typography {
        margin-bottom: 30px;
        position: relative;
        &:before {
            content: " ";
            position: absolute;
            background: #00a1de;
            width: 75px;
            height: 3px;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);O
        }        
    }
`
const TitleBox = () => {
    const { t } = useTranslation();
      return (
          <>
                  <StyledTitle>    
                      <Title level={3} type="black">
                      {t('language.price.saleModal.0')}
                      </Title>
                      <Text type="normal-color" style={{ textAlign: 'center' }}>
                      {t('language.price.saleModal.1')}
                      </Text>
                  </StyledTitle>
          </>
      )
  }
const CardBox = ({ title, price, list1, list2, list3, children}) => {
    const { t } = useTranslation();
    return (
      <>
    <Card
      hoverable
      style={{ width: 255, margin: '10px auto'}}
      className="modalLimitSale"
      cover={
        <>
        <div className="discountFiftyOff">
          <div className="shadowCover" />
          <img src={DiscountSVG} width="95%" alt="50%off" />
        </div>
        <Text style={{ color: '#626262', padding: '30px 0px 10px' }}>{title}</Text>
        <span>{t('language.price.modalMonth')}</span>
      <p className="price">{price}</p>
        <span style={{ fontSize: '14px', textDecoration: 'line-through' }}>$ 9,000</span>
        {children}
        </>
      }
    >
    <div style={{ color: '#626262', fontSize: '12px' }}><CheckOutlined style={{ color: '#52c41a', marginRight: 10 }} />{list1}</div>
    <div style={{ color: '#626262', fontSize: '12px'}}><CheckOutlined style={{ color: '#52c41a', marginRight: 10 }} />{list2}</div>
    <div style={{ color: '#626262', fontSize: '12px'}}><CheckOutlined style={{ color: '#52c41a', marginRight: 10 }} />{list3}</div>
    </Card>
      </>
    )
  }

const LimitSale = () => {
    const [modal, setModal] = useState(false);
    const { t } = useTranslation();
    const prices = [
        {
          price: "$2,999",
          className: ["Discount_1BrandEC", "Discount_1Game"],
          link: ["https://platform.goadhero.com/PaymentV2/Confirm?p=5003&d=1", "https://platform.goadhero.com/PaymentV2/Confirm?p=5003&d=1"]
        },
        {
          price: "$2,850",
          className: ["Discount_3BrandEC", "Discount_3Game"],
          link: ["https://platform.goadhero.com/PaymentV2/Confirm?p=5003&d=3", "https://platform.goadhero.com/PaymentV2/Confirm?p=5003&d=3"]
        },
        {
          price: "$2,700",
          className: ["Discount_6BrandEC", "Discount_6Game"],
          link: ["https://platform.goadhero.com/PaymentV2/Confirm?p=5003&d=6", "https://platform.goadhero.com/PaymentV2/Confirm?p=5003&d=6"]
        },
        {
          price: "$2,550",
          className: ["Discount_12BrandEC", "Discount_12Game"],
          link: ["https://platform.goadhero.com/PaymentV2/Confirm?p=5003&d=12", "https://platform.goadhero.com/PaymentV2/Confirm?p=5003&d=12"]
      }]
    return (
        <>
      <StyledLimitSale onClick={() => setModal(true)} data-content={t('language.price.saleButton')}>
        <LimitSaleSvg />
      </StyledLimitSale>
        <StyledModal
          centered
          width="600px"
          visible={modal}
          onCancel={() => setModal(false)}
          footer={null}
        >
          <TitleBox />
          <Tabs defaultActiveKey="0" centered="true" className="ant-tabs-centered">
            { prices.map((c, index) => {
              return (
                <TabPane tab={t('language.price.month.' + index)} key={index}>
                  <Row style={{ justifyContent: 'center' }}>
                    <Col md={24} lg={12} className="limitSaleCard">
                        <CardBox 
                        title={t('language.price.saleModal.2')} 
                        price={c.price} 
                        list1={t('language.price.saleModal.4')} 
                        list2={t('language.price.saleModal.5')}
                        list3="競品素材追蹤　　">
                          <Button className="limitSaleButtom" type="primary"><a className={c.className[0]} href={c.link[0]} target="_blank" rel="noopener noreferrer">
                            {t('language.price.signUp')}
                          </a></Button>
                        </CardBox>
                    </Col>
                  </Row>
                </TabPane>
              )
            })}
        </Tabs>
        </StyledModal>
        </>
    )
}

export { LimitSale, CardBox };