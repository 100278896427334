import i18n from 'i18next';
import React, { useState } from 'react';
import { I18nextProvider, initReactI18next,  } from 'react-i18next';

const en = require('../locales/en-US.json');
const ko = require('../locales/ko-KR.json');
const tw = require('../locales/zh-TW.json');
const jp = require('../locales/ja_JP.json');

const resources = {
  English: {
    translation: en,
  },
  한국어: {
    translation: ko,
  },
  繁體中文: {
    translation: tw,
  },
  日本語: {
    translation: jp,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: '繁體中文',
  fallbackLng: '繁體中文',
  interpolation: {
    escapeValue: false,
  },
});

type I18nContextProps = {
  currentLocale: string;
  changeLocale: (locale: string) => void;
};

export const I18nContext = React.createContext<I18nContextProps>({
  currentLocale: '繁體中文',
  changeLocale: () => {},
});

const I18nProvider: React.FC = ({ children }) => {
  const [i18nState, setI18nState] = useState(i18n);
  const [currentLocale, setCurrentLocale] = useState('繁體中文')
  const changeLocale = (locale: string) => {
    i18n.changeLanguage(locale);
    setCurrentLocale(locale);
    setI18nState(i18n);
  };
  return (
    <I18nContext.Provider
      value={{ changeLocale, currentLocale: currentLocale }}
    >
      <I18nextProvider i18n={i18nState}>{children}</I18nextProvider>
    </I18nContext.Provider>
  );
};

export default I18nProvider;
