import { Col, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import React from "react";

const FadeIn = ({ sequence, image, children, id }) => {
    switch(sequence) {
      case "true":   return (
        <Row id={id} style={{ paddingBottom: '20px' }}>
                <Col md={12} xs={24}>
                    <TweenOne
                        animation={{ x: 0, opacity: 1, ease: 'linear', duration: 200 }}
                        key="image"
                        className="willChange"
                    >              
                        {image}
                    </TweenOne>
                </Col>
                <Col md={12} xs={24} className="content">
                    <QueueAnim key="text" className="willChange" duration={200} leaveReverse type="right">
                            {children}             
                    </QueueAnim>
                </Col> 
        </Row>

      );
      case "false":   return (
        <Row id={id} className="mobileColumnReverse" style={{ paddingBottom: '20px' }}>
            <Col md={12} xs={24} className="content">
                <QueueAnim
                    key="text"
                    duration={200}
                    type="left"
                    leaveReverse
                    className="willChange"
                >
                    {children}                  
                </QueueAnim>
            </Col>
            <Col md={12} xs={24}>   
                <TweenOne
                    animation={{ x: 0, opacity: 1, ease: 'linear', duration: 200 }}
                    key="image"
                    className="willChange"
                >            
                    {image}
                </TweenOne>
            </Col>   
        </Row>
         
      );

      default: return (
        <></>
      )
    }     
}



export default FadeIn;