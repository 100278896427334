import React from 'react';
import { ReactSVG } from 'react-svg';
import AI from '../../static/Home/AI.svg';
import Analysis from '../../static/Home/analysis.svg';
import Email from '../../static/Home/email.svg';
import Market from '../../static/Home/market.svg';

const MarketSvg = () => {
    return (
        <ReactSVG src={Market} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const AISvg = () => {
    return (
        <ReactSVG src={AI} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />        
    )
}

const AnalysisSvg = () => {
    return (
        <ReactSVG src={Analysis} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />        
    )
}

const EmailSvg = () => {
    return (
        <ReactSVG src={Email} beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />           
    )
}


export { MarketSvg, AISvg, AnalysisSvg, EmailSvg };

