import { faFacebookSquare, faLine, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Divider, Layout, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link } from "react-router-dom";
import Styled from 'styled-components';
import Logo from '../../static/logo-light.png';

const { Footer } = Layout;

const IPad = ({ children }) => {
    const isIPad = useMediaQuery({ maxWidth: 992 })
    return isIPad ? null : children
  }

const StyledCopyright = Styled.div`
    background-color: #0d2134;
    color: #b7bcc2;
    text-align: center;
    @media (max-width: 992px) {
        padding-top: 20px;
    }
    .ant-divider-horizontal {
        background-color: #0d2134;
        margin: 0;
    }
    p {
        margin: 0;
        padding: 20px 0px;
    }
`

const StyledFooter = Styled(Footer)`
&.ant-layout-footer {
    padding: 70px 50px;
    @media only screen and (max-width: 992px) {
            padding: 40px 0px 10px 0px;
    }    
}
`
const StyledLinkBox = Styled.div `
    width: 140px; 
    margin: 0px auto;
    @media (max-width: 992px) {
        text-align: center;
    }
`
const StyledImage = Styled.div`
    width: 255px;
  @media (max-width: 990px) {
    margin: 10px auto;
  }
`;

const StyledP = Styled.p `
    font-size: 12px;
    white-space: nowrap;
    margin-bottom: 2.5em;
`

const StyledLink = Styled(Link)`
    color: #b7bcc2;
    &:hover {
        color: #00a1de;
    }
`
const StyledIconLink = Styled.p`
    margin-bottom: 0.5em;
    a {
        color: #b7bcc2;    
        &:hover {
            color: #00a1de;
        }        
    }

`

const Foot = () => {
    const { t } = useTranslation();
    const items = ["address", "time", "phone", "email"];
    const listItems = items.map((item, index) =>
        <p style={{ marginBottom: '0.5em' }} key={index}>{t('language.footer.'+ item +'.title')}:  {t('language.footer.'+ item +'.content')}</p>
    );    
    const handleClick = () => {
        document.documentElement.scrollTop = 0;
    }
    return (
        <>
        <StyledFooter>
            <Row>
                <Col lg={8} xs={24}>
                    <div style={{ width: '300px', margin: '0px auto' }}>
                        <StyledImage>
                            <img src={Logo} alt="logo" width="100%" />
                            <StyledP>EFUN INTERNATIONAL CORPORATION</StyledP>   
                        </StyledImage>
                        <IPad>
                            {listItems}
                        </IPad>
                    </div>
                </Col>
                <Col lg={8} xs={24} style={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end'
                }}>
                    <StyledLinkBox>
                        <p style={{ marginBottom: '0.5em' }}>
                            <StyledLink to="/eFun" onClick={handleClick}>
                                {t('language.header.3')}
                            </StyledLink>
                        </p>
                        <p style={{ marginBottom: '0.5em' }}>
                            <StyledLink to="/terms" onClick={handleClick}>
                                {t('language.footer.link.0')}
                            </StyledLink>
                        </p>
                        <p style={{ marginBottom: '0.5em' }}>
                            <StyledLink to="/privacy" onClick={handleClick}>
                                {t('language.footer.link.1')}
                            </StyledLink>
                        </p>   
                        <StyledIconLink>
                            <a href="https://www.facebook.com/AdHero-%E6%95%B8%E4%BD%8D%E5%BB%A3%E5%91%8AAI%E6%99%BA%E8%83%BD%E5%A4%A5%E4%BC%B4-100636475229353" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare} style={{ fontSize: '35px', marginRight: 10 }}/></a>
                            <a href="https://www.youtube.com/channel/UCPxj_ad6AFH9s89H4T8w05g" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutubeSquare} style={{ fontSize: '35px', marginRight: 10 }}/></a>
                            <a href="https://line.me/R/ti/p/@883alngx" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLine} style={{ fontSize: '35px' }}/></a>
                        </StyledIconLink>  
                    </StyledLinkBox> 
                </Col>
                <Col lg={8} xs={0} style={{ textAlign: 'right' }}>

                        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.42341457736!2d121.5948699156656!3d25.053634243656617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ab6456ff6fdb%3A0xdde3e07196811425!2zMTE15Y-w5YyX5biC5Y2X5riv5Y2A5Y2X5riv6Lev5LqM5q61OTktMTDomZ8!5e0!3m2!1szh-TW!2stw!4v1593676521702!5m2!1szh-TW!2stw" width="100%" height="220" frameBorder="0" style={{ border:0 }} allowFullScreen={false} aria-hidden="false" tabIndex="0" />
     
                </Col>
            </Row>
        </StyledFooter>
        <StyledCopyright>
            <Divider />
            <p>{new Date().getFullYear()} © EFUN All Rights Reserved.</p>
        </StyledCopyright>
        </>
    )
}

export default Foot;