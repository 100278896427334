import React from 'react';
import { ReactSVG } from 'react-svg';
import Material1 from '../../static/landing/material1.svg';
import Material2 from '../../static/landing/material2.svg';
import Material3 from '../../static/landing/material3.svg';
import Material4 from '../../static/landing/material4.svg';
import AI1 from '../../static/landing/AI1.svg';
import AI2 from '../../static/landing/AI2.svg';
import AI3 from '../../static/landing/AI3.svg';

const  Material1Svg = () => {
    return (
        <ReactSVG src={Material1}  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const  Material2Svg = () => {
    return (
        <ReactSVG src={Material2}  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const  Material3Svg = () => {
    return (
        <ReactSVG src={Material3}  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const  Material4Svg = () => {
    return (
        <ReactSVG src={Material4}  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const  AI1Svg = () => {
    return (
        <ReactSVG src={AI1}  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const  AI2Svg = () => {
    return (
        <ReactSVG src={AI2}  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const  AI3Svg = () => {
    return (
        <ReactSVG src={AI3}  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

export { Material1Svg, Material2Svg, Material3Svg, Material4Svg, AI1Svg, AI2Svg, AI3Svg };