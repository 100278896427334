import { Button, Layout, Modal, Tag, Typography } from 'antd';
import ScrollAnim from 'rc-scroll-anim';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/youtube';
import { Link } from "react-router-dom";
import Styled from "styled-components";
import { LandingPageContext } from '../../context/LandingPageContext';
import { ScrollContext } from '../../context/ScrollContext';
import Banner from '../../static/about/banner.jpg';
import ContainerBg from '../../static/pageBg.png';
import ContainerBg2 from '../../static/pageBg2.png';
import FadeIn from '../FadeIn/FadeIn';
import FormGroup from '../Form/FormGroup';
import ModalRefund from '../Modal/ModalRefund';
import { AccountSvg, AppSvg, BudgetSvg, HealthSvg, MarketSvg, MaterialSvg, MediaSvg, WatchVideoSvg } from '../Svg/About';
import Layouts from '../Layout/index';

const Links = ScrollAnim.Link;
const { Title, Text } = Typography;
const { Content } = Layout;

const StyledBanner = Styled.div`
        background: url(${Banner});
        background-repeat: no-repeat;
        background-size: cover;   
        height: 100vh; 
`
const StyledTitleBox = Styled.div`
    text-align: center;
    padding: 70px 0px 0px 0px; 
    @media (max-width: 768px) {
        padding: 60px 0px 0px 0px;
    }
    h3.ant-typography {
        margin-bottom: 30px;
        position: relative;
        &:before {
            content: " ";
            position: absolute;
            background: #00a1de;
            width: 75px;
            height: 3px;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
        }        
    }
`
const Container = Styled(Content)`
    overflow: hidden;
    padding: 0px 50px 70px;
    @media (max-width: 768px) {
        padding: 0px 10px 40px;
    }
`
const Wrapper = Styled.div`
    background: url(${ContainerBg});
    background-repeat: no-repeat;
    background-size: 100% 1443px;
    background-position: top center;
    position: relative;
    overflow: hidden;
    @media (max-width: 1024px) {
        background-size: 100% 1200px;
    }
    @media (max-width: 768px) {
        background-size: 100% 700px;
    }
    &:before {
        content: " ";
        position: absolute;
        background: url(${ContainerBg2});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 50%;
        background-position: top center;
        transform: translateY(100%);
        z-index: -1;
    }
`
const TitleBox = ({ email }) => {
    const { t } = useTranslation();
    return (
        <>
            { email ? ( 
                <StyledTitleBox>    
                    <Title level={3} type="black">
                        {t('language.form.title')}
                    </Title>
                    <Text type="normal-color" style={{ textAlign: 'center' }}>
                        {t('language.form.subtitle')}
                    </Text>
                </StyledTitleBox>
            ) : (
                <StyledTitleBox>
                    <Title level={3} type="black">
                        {t('language.about.Introduction.title')}
                    </Title>
                </StyledTitleBox>            
            )}
        </>
    )
}

const About = () => {
    const { t } = useTranslation();
    const { onScrollEnterChange } = useContext(ScrollContext);
    const onEnterChange = (mode) => {
        onScrollEnterChange(mode === 'enter')
    }
    const [video, setVideo] = useState(false); 
    const handleClick = () => {
        document.documentElement.scrollTop = 0;
    }
    const [refundModal, setRefundModal] = useState(false);
    const { onLandingPage } = useContext(LandingPageContext);
    useEffect(() => {
        onLandingPage(false);
    });

    return (
        <Layouts>
        <Modal
          title="     "
          centered
          visible={video}
          footer={null}
          onCancel={() => setVideo(false)}
          width="80vw"
          className="videoModal"
        >
          <ReactPlayer playing={video} url='https://www.youtube.com/watch?v=S4V-LCQ9q1c' width="100%" height="80vh" />
        </Modal>
        <ModalRefund refundModal={refundModal} setRefundModal={() => setRefundModal(false)}/>
        <StyledBanner>
        <div className="hero">
                    <Title level={2} type="warning">
                        {t('language.about.banner.title')}
                    </Title>
                    <div style={{ cursor: 'pointer' }} onClick={() => setVideo(true)}><WatchVideoSvg /></div>
                    <Button size="default"><a href="https://platform.goadhero.com/Home/Index?ReturnUrl=%2f">{t('language.about.banner.button')}</a></Button>
                </div>
                <Links to="page2"><div className="scrollPointer"></div></Links>
        </StyledBanner>
        <ScrollElement
            id="page2"
            onChange={({ mode }) => onEnterChange(mode)}
            playScale={0.1}>
                <Wrapper section1={true}>
                    <Container>
                        <TitleBox email={false} />
                        <FadeIn id="marketValue" sequence="true" image={<MarketSvg />} >
                            <div key="box" >
                                <Title level={4} type="black">{t('language.about.Introduction.content.section1.title')}</Title>
                                <Text>{t('language.about.Introduction.content.section1.content')}</Text>
                                <div style={{ marginTop: '5%' }}>
                                <Tag color="#21a1df">CTR</Tag>
                                <Tag color="#31c3a5">CPC</Tag>
                                <Tag color="#f7b84a">CPM</Tag>
                                <Tag color="#f64e69">CPA</Tag>
                                <Tag color="#38cde7">VTR</Tag>
                                <Tag color="#f671a8">CPV</Tag>
                                <Tag color="#6559cc">CVR</Tag>
                                </div>
                            </div>
                        </FadeIn>
                        <FadeIn id="marketMaterial" sequence="false" image={<MaterialSvg />} >
                            <div key="box">
                                <Title level={4} type="black" >{t('language.about.Introduction.content.section2.title')}</Title>
                                <Text>{t('language.about.Introduction.content.section2.content')}</Text>
                                <div style={{ marginTop: '5%',display: 'flex',justifyContent: 'space-between',alignItems: 'flex-end' }}>
                                    <div>
                                    <Tag color="#21a1df">{t('language.about.Introduction.content.section2.tag.0')}</Tag>
                                    <Tag color="#21a1df">{t('language.about.Introduction.content.section2.tag.1')}</Tag>
                                    </div>
                                    <span style={{ textAlign: 'right' }}>
                                        <Link to="/LandingPage" onClick={handleClick}><Button type="link" style={{ borderColor: '#00a1de' }}>{t('language.about.Introduction.content.section2.button')}</Button></Link>
                                    </span>
                                </div>
                            </div>
                        </FadeIn>
                        <FadeIn id="adAccountMaterial" sequence="true" image={<AccountSvg />} >
                            <div key="box">
                                <Title level={4} type="black" >{t('language.about.Introduction.content.section3.title')}</Title>
                                <Text>{t('language.about.Introduction.content.section3.content')}</Text>
                                <div style={{ marginTop: '5%' }}>
                                <Tag color="#ea4335">Google</Tag>
                                <Tag color="#4267b2">Facebook</Tag>
                                </div>
                            </div>
                        </FadeIn>
                        {/* <FadeIn sequence="false" image={<EffectSvg />} >
                            <div key="box">
                                <Title level={4} type="black" >{t('language.about.Introduction.content.section4.title')}</Title>
                                <Text>{t('language.about.Introduction.content.section4.content')}</Text>
                                <div style={{ marginTop: '5%' }}>
                                <Tag color="#21a1df">{t('language.about.Introduction.content.section2.tag.0')}</Tag>
                                <Tag color="#21a1df">{t('language.about.Introduction.content.section2.tag.1')}</Tag>
                                </div>
                            </div>
                        </FadeIn> */}
                        <FadeIn sequence="false" image={<HealthSvg />} >
                            <div key="box">
                                <Title level={4} type="black" >{t('language.about.Introduction.content.section5.title')}</Title>
                                <Text>{t('language.about.Introduction.content.section5.content')}</Text>
                                <div style={{ marginTop: '5%' }}>
                                <Tag color="#ea4335">Google</Tag>
                                <Tag color="#4267b2">Facebook</Tag>
                                </div>
                            </div>
                        </FadeIn>
                        <FadeIn sequence="true" image={<AppSvg />} >
                            <div key="box">
                                <Title level={4} type="black" >{t('language.about.Introduction.content.section6.title')}</Title>
                                <Text>{t('language.about.Introduction.content.section6.content')}</Text>
                                <div style={{ marginTop: '5%' }}>
                                <Tag color="#ea4335">Google</Tag>
                                </div>
                            </div>
                        </FadeIn>
                        <FadeIn sequence="false" image={<BudgetSvg />} >
                            <div key="box">
                                <Title level={4} type="black">{t('language.about.Introduction.content.section7.title')}</Title>
                                <Text>{t('language.about.Introduction.content.section7.content')}</Text>
                                <div style={{ marginTop: '5%' }}>
                                <Tag color="#ea4335">Google</Tag>
                                <Tag color="#4267b2">Facebook</Tag>
                                </div>
                            </div>
                        </FadeIn>
                        <FadeIn sequence="true" image={<MediaSvg />} >
                            <div key="box"><Title level={4} type="black">{t('language.about.Introduction.content.section8.title')}</Title>
                                <Text>{t('language.about.Introduction.content.section8.content')}</Text>
                                <div style={{ marginTop: '5%' }}>
                                <Text>{t('language.about.Introduction.content.section8.tagTitle.0')}</Text><br />
                                <Tag color="#ea4335">Google</Tag>
                                <Tag color="#4267b2">Facebook</Tag>
                                </div>
                                <div style={{ marginTop: '5%' }}>
                                <Text>{t('language.about.Introduction.content.section8.tagTitle.1')}</Text><br />
                                <Tag color="#28a745">LINE</Tag>
                                </div>
                            </div>
                        </FadeIn>
                    </Container>
                </Wrapper>
            </ScrollElement>    
                <Container style={{ overflow: 'none' }}>
                        <div className="recommendBox" style={{ margin: '0px auto 0px' }}>
                            <a href="https://platform.goadhero.com/Home/Registered" target="_blank" rel="noopener noreferrer"><Button type="primary">{t('language.about.Introduction.content.recommend.button.0')}</Button></a>
                            <div style={{ marginTop: '8px' }}><Button type="danger" className="refundText" onClick={() => setRefundModal(true)}>{t('language.about.Introduction.content.recommend.button.1')}</Button></div>
                        </div>
                </Container>
            <Container style={{ background: '#f7f9fc' }}>  
                <TitleBox email={true} />  
                <FormGroup />
            </Container>   
        </Layouts>
    )
}

export default About;