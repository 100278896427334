import React from "react";
import Styled from "styled-components";
import { Button, Typography } from 'antd';
import { Link } from "react-router-dom";
import Layout from './components/Layout/index';

const StyledBody = Styled.div`
    position: relative;
    height: 100vh;
    background-color: #0d2134;
`
const StyledCenterBox = Styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`

const { Text, Title } = Typography;


const NouFound = () => {
    return (
        <Layout>
            <StyledBody>
                <StyledCenterBox>
                    <Title level={1} style={{ color: '#fff', textAlign: 'center'}}>Oops! 404</Title>
                    <Text style={{ color: '#fff'}}>Sorry, the page you visited does not exist.</Text>
                    <br />
                    <br />
                    <Link to="/"><Button type="primary">Back to home page</Button></Link>
                </StyledCenterBox>
            </StyledBody>
        </Layout>
    )
}

export default NouFound;