import { Layout, Typography } from 'antd';
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Styled from "styled-components";
import { ScrollContext } from '../../context/ScrollContext';
import Layouts from '../Layout/index';
import { useTranslation } from 'react-i18next';
const { Title } = Typography;
const { Content } = Layout;

const Container = Styled(Content)`
    overflow: hidden;
    padding: 100px 70px 50px 70px;
    text-align: justify;
    @media (max-width: 768px) {
        padding: 60px 5%;
    }
`

const Terms = () => {
    const { t } = useTranslation();
    const { onScrollEnterChange } = useContext(ScrollContext);
    useEffect(() => {
        onScrollEnterChange('enter')
      });
    return (
        <Layouts>
        <Container>
            <Title className="termsTitle" level={2}>{t('language.terms.title')}</Title>
            <>
                                <p dangerouslySetInnerHTML={{__html: t('language.terms.context')}} />
                                <p><b>{t('language.terms.list1.title')}</b></p>
                                <p>{t('language.terms.list1.item1')}</p>
                                <p>{t('language.terms.list1.item2')}</p>
                                <p>{t('language.terms.list1.item3')}</p>
                                <p><b>{t('language.terms.list2.title')}</b></p>
                                <p>{t('language.terms.list2.item1OP')}<Link to="/price" target="_blank" rel="noopener noreferrer">{t('language.terms.plan')}</Link>{t('language.terms.list2.item1ED')}</p>
                                <p>{t('language.terms.list2.item2')}</p>
                                <p>{t('language.terms.list2.item3')}</p>
                                <p><b>{t('language.terms.list3.title')}</b></p>
                                <p>{t('language.terms.list3.item1OP')}</p>
                                <p>{t('language.terms.list3.item1ED')}</p>
                                <p>{t('language.terms.list3.item2')}</p>
                                <p>{t('language.terms.list3.item3')}</p>
                                <p>{t('language.terms.list3.item4')}</p>
                                <p>{t('language.terms.list3.item5')}</p>
                                <p>{t('language.terms.list3.item6')}</p>
                                <p><b>{t('language.terms.list4.title')}</b></p>
                                <p>{t('language.terms.list4.item1')}</p>
                                <p>{t('language.terms.list4.item2')}</p>
                                <p>{t('language.terms.list4.item3')}</p>
                                <p>{t('language.terms.list4.item4')}</p>
                                <p>{t('language.terms.list4.item5')}</p>
                                <p><b>{t('language.terms.list5.title')}</b></p>
                                <p>{t('language.terms.list5.item1')}</p>
                                <p>{t('language.terms.list5.item2')}</p>
                                <p><b>{t('language.terms.list6.title')}</b></p>
                                <p>{t('language.terms.list6.item1')}</p>
                                <p>{t('language.terms.list6.item2')}</p>
                                <p>{t('language.terms.list6.item3')}</p>
                                <p>{t('language.terms.list6.item4')}</p>
                                <p><b>{t('language.terms.list7.title')}</b></p>
                                <p>{t('language.terms.list7.item1OP')}<Link to="/privacy" target="_blank" rel="noopener noreferrer">{t('language.terms.privacy')}</Link>{t('language.terms.list7.item1ED')}</p>
                                <p><b>{t('language.terms.list8.title')}</b></p>
                                <p>{t('language.terms.list8.item1')}</p>
                                <p>{t('language.terms.list8.item2')}</p>
                                <p>{t('language.terms.list8.item3')}</p>
                                <p><b>{t('language.terms.list9.title')}</b></p>
                                <p>{t('language.terms.list9.item1')}</p>
                                <p><b>{t('language.terms.list10.title')}</b></p>
                                <p>{t('language.terms.list10.item1')}</p>
                                <p>{t('language.terms.telephone')}：<a href="tel:+886-2-2788-1111">02-2788-1111</a></p>
                                <p>{t('language.terms.email')}：<a href="mailto:service@goadhero.com">service@goadhero.com</a></p>
            </>
        </Container>
    </Layouts>
    )
}

export default Terms;