import React from 'react';
import { ReactSVG } from 'react-svg';
import Account from '../../static/about/account.svg';
import App from '../../static/about/app.svg';
import Budget from '../../static/about/budget.svg';
import Effect from '../../static/about/effect.svg';
import Health from '../../static/about/health.svg';
import Market from '../../static/about/market.svg';
import Material from '../../static/about/material.svg';
import Media from '../../static/about/media.svg';
import WatchVideo from '../../static/about/watchVideo.svg';

const WatchVideoSvg = () => {
    return (
        <ReactSVG src={WatchVideo}  beforeInjection={svg => {
            svg.setAttribute('style', 'height: 55px')
          }} />
    )
}

const MarketSvg = () => {
    return (
        <ReactSVG src={Market} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const MaterialSvg = () => {
    return (
        <ReactSVG src={Material} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const AccountSvg = () => {
    return (
        <ReactSVG src={Account} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const EffectSvg = () => {
    return (
        <ReactSVG src={Effect} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const HealthSvg = () => {
    return (
        <ReactSVG src={Health} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const AppSvg = () => {
    return (
        <ReactSVG src={App} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const BudgetSvg = () => {
    return (
        <ReactSVG src={Budget} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

const MediaSvg = () => {
    return (
        <ReactSVG src={Media} className="imageContent"  beforeInjection={svg => {
            svg.setAttribute('style', '')
          }} />
    )
}

export { MediaSvg, MarketSvg, MaterialSvg, AccountSvg, EffectSvg, HealthSvg, AppSvg, BudgetSvg, WatchVideoSvg };

