import React from "react";
import ReactDOM from "react-dom";
import TagManager from 'react-gtm-module';
import { BrowserRouter, Switch } from "react-router-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const tagManagerArgs = {
    gtmId: 'GTM-52JMQJ6'
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <App />
        </Switch>
    </BrowserRouter>,
 document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
