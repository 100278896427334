import { Col, Row } from 'antd';
import React from "react";
import { EmailSvg } from '../Svg/Home';
import FormInput from './FormInput';

const FormGroup = () => {
    return (
        <>
        <Row className="contact">
            <Col xs={24} lg={12}>
                <EmailSvg />            
            </Col>
            <Col xs={24} lg={12} style={{ display: 'flex',  alignItems: 'center' }}>
                <FormInput />
            </Col>
        </Row>
        </>
    )
}

export default FormGroup;