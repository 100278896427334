import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { BackTop } from 'antd';
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.less";
import About from './components/About';
import EFun from './components/EFun';
import Home from './components/Home';
import LandingPage from './components/LandingPage';
import Price from './components/Price';
import Privacy from './components/Privacy/Privacy';
import Terms from './components/Terms/Terms';
import CurrentLocalContext from './context/CurrentLocalContext';
import I18nContext from './context/I18nContext';
import LandingPageContext from './context/LandingPageContext';
import ScrollContext from './context/ScrollContext';
import NotFound from './NotFound';

const style = {
  height: 40,
  width: 40,
  lineHeight: '40px',
  borderRadius: 4,
  backgroundColor: '#00a1de',
  color: '#fff',
  textAlign: 'center',
  fontSize: 26,
};


function App() {
  return (

    <>
      <I18nContext>
        <ScrollContext>
        <LandingPageContext>
          <CurrentLocalContext>
            <Router>
              <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/about" component={About} />
                  <Route path="/price" component={Price} />
                  <Route path="/eFun" component={EFun} />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/terms" component={Terms} />
                  <Route path="/LandingPage" component={LandingPage} /> 
                  <Route path="//" exact component={Home} />
                  <Route path="//about" component={About} />
                  <Route path="//price" component={Price} />
                  <Route path="//eFun" component={EFun} />
                  <Route path="//privacy" component={Privacy} />
                  <Route path="//terms" component={Terms} />
                  <Route path="//LandingPage" component={LandingPage} /> 
                  <Route component={NotFound} />
              </Switch>
            </Router>
          </CurrentLocalContext>
        </LandingPageContext>
        </ScrollContext>
      </I18nContext>
      <BackTop>
        <div style={style}><VerticalAlignTopOutlined /></div>
      </BackTop>
      
    </>
  );
}

export default App;
