import { Button, Col, Modal, Row, Typography } from 'antd';
import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Styled from "styled-components";
import Refund from '../../static/landing/refund.png';

const { Title, Text } = Typography;
const StyledRefundModal = Styled(Modal)`
    .ant-modal-body{
        text-align: center;
        .bTIjTR {
            padding: 30px 0px 30px 0px;
        }
        button.ant-btn-primary {
            padding: 8px 16px;
            border-radius: 5px;
            font-size: 18px;
        }
        .terms {
            margin-top: 18px;
        }        
    }
`
const StyledTitleBox = Styled.div`
    text-align: center;
    padding: 150px 0px 30px 0px; 
    @media (max-width: 768px) {
        padding: 60px 0px 0px 0px;
    }
    h1.ant-typography {
      @media(max-width: 767px) {
        font-size: 2rem;
        margin: 0.5em 0px;
      }
    }
    h2.ant-typography {
        margin: 30px 0px;
        position: relative;  
        font-size: 2rem;      
    }
`

const ModalRefund = ({ refundModal, setRefundModal }) => {
    const { t } = useTranslation();
    return (
        <StyledRefundModal
            title=" "
            centered
            width="90vw"
            footer={null}
            visible={refundModal}
            onCancel={setRefundModal}
            >
            <StyledTitleBox style={{ padding: '0px 0px 30px 0px' }}>
                <Title level={3} type="black">
                    七天鑑賞期全額退款
                </Title>
                <Text type="normal-color" style={{ textAlign: 'center' }}>
                    凡 AdHero 任一方案/自由配功能，<span style={{ color: 'rgb(255, 77, 79)' }}>首次購買後七天內都可享全額退款。</span>
                    <br />在方案管理 {'>'} 付款紀錄，就可以看到退款鈕囉！退款輕鬆又簡單！
                </Text>
                <Row>
                    <Col span={24}>
                        <img src={Refund} width="100%" alt="refund" />
                    </Col>
                </Row>
            </StyledTitleBox>
            <Button type="primary" onClick={setRefundModal}>我瞭解了</Button>
            <br />
            <p className="terms">
                想了解更多關於退款規則，請參閱我們的
                <Link to="/terms" onClick={() => {document.documentElement.scrollTop = 0;}}>{t('language.footer.link.0')}</Link>
            </p>
        </StyledRefundModal>
    )
}

export default ModalRefund;