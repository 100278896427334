import { Button, Card, Col, Layout, Row, Typography } from 'antd';
import ScrollAnim from 'rc-scroll-anim';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import TweenOne from 'rc-tween-one';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player';
import Styled from "styled-components";
import { ScrollContext } from '../../context/ScrollContext';
import LogoSvg from '../../static/Home/adheroLogo.svg';
import ContainerBg from '../../static/pageBg.png';
import ContainerBg2 from '../../static/pageBg2.png';
import Mp4Desktop from '../../static/video/Adhero.mp4';
import MovDesktop from '../../static/video/Adhero.mov';
import FadeIn from '../FadeIn/FadeIn';
import FormGroup from '../Form/FormGroup';
import { AISvg, AnalysisSvg, MarketSvg } from '../Svg/Home';
import { LandingPageContext } from '../../context/LandingPageContext';
import Layouts from '../Layout/index';

import { Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import reqwest from 'reqwest';

const ScrollOverPack = ScrollAnim.OverPack;
const Links = ScrollAnim.Link;
const { Title, Text } = Typography;
const { Content } = Layout;

const StyledTitleBox = Styled.div`
    text-align: center;

    padding: 70px 0px; 
    @media (max-width: 768px) {
        padding: 60px 0px 60px 0px;
    }
    h3.ant-typography {
        margin-bottom: 30px;
        position: relative;
        &:before {
            content: " ";
            position: absolute;
            background: #00a1de;
            width: 75px;
            height: 3px;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
        }        
    }
`
const Container = Styled(Content)`
    overflow: hidden;
    padding: 0px 50px 70px;
    @media (max-width: 768px) {
        padding: 0px 10px 40px;
    }
`
const Wrapper = Styled.div`
    background: url(${ContainerBg});
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: top center;
    position: relative;
    overflow: hidden;
    @media (max-width: 1024px) {
        background-size: 100% 1200px;
    }
    @media (max-width: 768px) {
        background-size: 100% 700px;
    }
    &:before {
        content: " ";
        position: absolute;
        background: url(${ContainerBg2});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 50%;
        background-position: top center;
        transform: translateY(100%);
        z-index: -1;
    }
    &:after {
        content: " ";
        position: absolute;
        background: url(${LogoSvg});  
        width: 756px;
        height: 100px;
        right: 60px;
        bottom: 0px;   
        background-repeat: no-repeat; 
        z-index: -1;
        @media (max-width: 1024px) {
            width: 500px;
            height: 65px;
            right: 40px;
        } 
        @media (max-width: 768px) {
            width: 372px;
            height: 50px;
            right: 0;
            bottom: 0;
        }    
        @media (max-width: 415px) {
            width: 250px;
            height: 50px;
            right: 6px;
            bottom: 0px;
        }       
    }

`
const TitleBox = ({ email }) => {
    const { t } = useTranslation();
    return (
        <>
            { email ? ( 
                <StyledTitleBox>    
                    <Title level={3} type="black">
                        {t('language.form.title')}
                    </Title>
                    <Text type="normal-color" style={{ textAlign: 'center' }}>
                        {t('language.form.subtitle')}
                    </Text>
                </StyledTitleBox>
            ) : (
                <StyledTitleBox>
                    <Title level={3} type="black">
                    {t('language.home.Introduction.title')}
                    </Title>
                    <Text type="normal-color" style={{ textAlign: 'center' }}>
                    {t('language.home.Introduction.subTitle')}
                    </Text>
                </StyledTitleBox>            
            )}
        </>
    )
}

const Index = () => {
    const { t } = useTranslation();
    const { onScrollEnterChange } = useContext(ScrollContext);
    const onEnterChange = (mode) => {
        onScrollEnterChange(mode === 'enter')
    } 
    const handleClick = () => {
        document.documentElement.scrollTop = 0;
    }
    const { onLandingPage } = useContext(LandingPageContext);
    useEffect(() => {
        onLandingPage(false);
    });
    return (
        <Layouts>
            <div className="video-container">
            <ReactPlayer
                loop={true}
                muted={true}
                playing={true}
                controls={false}
                playsinline={true}
                className='react-player'
                url={[
                    {src: Mp4Desktop, type: 'video/mp4'},
                    {src: MovDesktop, type: 'video/mov'}
                  ]}
                width='100%'
                height='100%'
            />
                <div className="hero">
                    <Title level={2} type="warning">
                        {t('language.home.banner.title')}
                    </Title>
                    <p>
                    {t('language.home.banner.content')}
                    </p>
                    <Button size="default"><a href="https://platform.goadhero.com/Home/Index?ReturnUrl=%2f" target="_blank" rel="noopener noreferrer">{t('language.home.banner.button')}</a></Button>
                </div>
                <Links to="page1"><div className="scrollPointer"></div></Links>
            </div>
            <ScrollElement
            id="page1"
            onChange={({ mode }) => onEnterChange(mode)}
            playScale={0.1}>
                <Wrapper section1={true}>
                    <Container>
                        <TitleBox email={false} />
                        <ScrollOverPack
                            always={false}
                            id="page2"
                            key="2"
                            >
                        <FadeIn sequence="true" image={<MarketSvg />}  >
                            <Card size="default" className="textContent" key="box">
                                    <Title level={3} type="black">
                                        {t('language.home.Introduction.content.section1.title')}
                                    </Title>
                                    <Text>
                                        {t('language.home.Introduction.content.section1.content')}
                                    </Text>
                                    <Link  to="/about#marketValue" onClick={() => {
                                        document.documentElement.scrollTop = window.innerHeight;
                                    }}>
                                    <Button type="primary">
                                        {t('language.home.Introduction.content.section1.button')}
                                    </Button>
                                    </Link>
                            </Card>
                        </FadeIn>
                        </ScrollOverPack>
                        <ScrollOverPack
                            always={false}
                            id="page3"
                            key="3"
                            >
                        <FadeIn sequence="false" image={<AISvg />} >
                            <Card size="default" className="textContent" key="box">
                                <Title level={3} type="black">
                                    {t('language.home.Introduction.content.section2.title')}
                                </Title>
                                <Text>
                                    {t('language.home.Introduction.content.section2.content')}
                                </Text>
                                <Link to="/about#marketMaterial" onClick={() => {
                                        document.documentElement.scrollTop = window.innerHeight;
                                    }}>
                                <Button type="primary">
                                    {t('language.home.Introduction.content.section2.button')}
                                </Button>
                                </Link>
                            </Card>
                        </FadeIn>
                        </ScrollOverPack>
                        <ScrollOverPack
                            always={false}
                            id="page4"
                            key="4"
                            >
                        <FadeIn sequence="true" image={<AnalysisSvg />} >
                            <Card size="default" className="textContent" key="box">
                                <Title level={3} type="black">
                                    {t('language.home.Introduction.content.section3.title')}
                                </Title>
                                <Text>
                                    {t('language.home.Introduction.content.section3.content')}
                                </Text>
                                <Link to="/about#adAccountMaterial" onClick={() => {
                                        document.documentElement.scrollTop = window.innerHeight;
                                    }}>
                                    <Button type="primary">
                                        {t('language.home.Introduction.content.section3.button')}
                                    </Button>
                                </Link>
                            </Card> 
                        </FadeIn>
                        </ScrollOverPack>
                    </Container>
                </Wrapper>
            </ScrollElement>    
                <Container>
                    <TweenOne
                    animation={{ y: 0, opacity: 1, ease: 'easeOutQuart', }}
                    style={{ transform: 'translateY(100px)', opacity: 0 }}>
                        <div className="recommendBox">
                            <Title type="primary">
                                {t('language.home.Introduction.content.recommend.title.0')}
                                <br />
                                {t('language.home.Introduction.content.recommend.title.1')}
                            </Title>
                            <Row>
                                <Col md={12} xs={24}><Link to="/about" onClick={handleClick}><Button type="link">{t('language.home.Introduction.content.recommend.button.0')}</Button></Link></Col>
                                <Col md={12} xs={24}><a href="https://platform.goadhero.com/" target="_blank" rel="noopener noreferrer"><Button type="primary">{t('language.home.Introduction.content.recommend.button.1')}</Button></a></Col>
                            </Row>
                        </div>
                    </TweenOne>
                </Container>
            <Container style={{ background: '#f7f9fc' }}>  
                <TitleBox email={true} />  
                <FormGroup />
            </Container>   
        </Layouts>
    )
}





export default Index;