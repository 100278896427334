import { Button, Form, Input } from 'antd';
import emailjs from 'emailjs-com';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormGroup = () => {

    const { t } = useTranslation();
    const onFinish = values => {
      
     const templateParams = {
      "from_name": values.user.name,
      "from_email": values.user.email,
      "message_html": values.user.introduction
  };
   
  emailjs.send('default_service','template_7vJ8DTUc', templateParams, 'user_51UecnftvWtOd8SyF9JNz')
      .then((response) => {
         console.log('SUCCESS!', response.status, response.text);
      }, (err) => {
         console.log('FAILED...', err);
      });


        fetch(`https://script.google.com/macros/s/AKfycbz3Fmnpj_hj5Nizp43WR1gfIeQ2UnavhY92FJQhx1oJlWLVVXLr/exec`,{
          method:'POST',
          body: encodeURI(JSON.stringify({
            name: values.user.name,
            email: values.user.email,
            introduction: values.user.introduction
          })),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
          }
        })
          .then((response) => response.json())
          .then((object) => alert('SUCCESS！我們將盡快與您聯繫，感謝您的來信'))
          .catch((error) => console.log(error))
      };
      return (
        <Form name="nest-messages" onFinish={onFinish} action="https://formspree.io/mzbjkyqd" method="POST">
        <Form.Item
          name={['user', 'name']}
          label={t('language.form.name')}
          rules={[
            {
              required: true,
              message: 'Ops！您尚未填寫您的大名。',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={['user', 'email']}
          label={t('language.form.email')}
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Ops！您尚未填寫您的電子信箱。',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={['user', 'introduction']} label={t('language.form.textarea')}
                  rules={[
                    {
                      required: true,
                      message: '有任何問題都可以填寫詢問呦！',
                    },
                  ]}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
            {t('language.form.submit')}
          </Button>
        </Form.Item>
      </Form>
      )
  }

 export default FormGroup;

