import React, { useState } from 'react';

export const ScrollContext = React.createContext({
    isFirstScreen: '',
    onScrollEnterChange: () => {},
});

const ScrollProvider = ({ children }) => {
    const [isFirstScreenState, setIsFirstScreenState] = useState('');
    const onScrollEnterChange = (mode) => {
        setIsFirstScreenState(mode)
    }
    return (
        <ScrollContext.Provider 
        value={{
            isFirstScreen: isFirstScreenState, 
            onScrollEnterChange
        }}>
            {children}
        </ScrollContext.Provider>
    )
}

export default ScrollProvider;