import { Button, Layout, Typography } from 'antd';
import ScrollAnim from 'rc-scroll-anim';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import React, { useContext, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Styled from "styled-components";
import { ScrollContext } from '../../context/ScrollContext';
import Adwitt from '../../static/efun/adwitt.svg';
import AppleReport from '../../static/efun/Apple.png';
import Apple from '../../static/efun/ASA.png';
import Banner from '../../static/efun/banner.jpg';
import BlueStacks from '../../static/efun/blueStacks.png';
import ChinaTimes from '../../static/efun/chinatimes.png';
import CK from '../../static/efun/ck.png';
import Dcard from '../../static/efun/Dcard.png';
import EFunAwards from '../../static/efun/efunAwards.svg';
import EFUN from '../../static/efun/efun_logo.png';
import Emulator from '../../static/efun/emulator.png';
import ETToday from '../../static/efun/ETToady.png';
import Facebook from '../../static/efun/FacebookMarketingParther.svg';
import GameBase from '../../static/efun/gamebase.png';
import Gamer from '../../static/efun/gamer.png';
import GoogleMarket from '../../static/efun/GoogleMarketingPlatformBadges.png';
import GoogleMarketPartner from '../../static/efun/GooglePartner.svg';
import KOCHAVA from '../../static/efun/KOCHAVA.png';
import Line from '../../static/efun/line.png';
import LINE from '../../static/efun/LINE.svg';
import MyCard from '../../static/efun/mycard.png';
import OpenChannel from '../../static/efun/OpenChannel.png';
import OpenPoint from '../../static/efun/openpoint.png';
import QIY from '../../static/efun/Qiy.png';
import QOO from '../../static/efun/qoo.png';
import Read from '../../static/efun/READ.svg';
import ThunderEmulator from '../../static/efun/thunderEmulator.png';
import TikTokLogo from '../../static/efun/tiktok.png';
import TikTok from '../../static/efun/TikTok.svg';
import TimeReport from '../../static/efun/timeReport.png';
import Twitch from '../../static/efun/twitch.png';
import Udn from '../../static/efun/udn.png';
import Wisebirs from '../../static/efun/wisebirs.svg';
import Yahoo from '../../static/efun/YAHOO.png';
import AdHero from '../../static/logo.png';
import ContainerBg from '../../static/pageBg.png';
import ContainerBg2 from '../../static/pageBg2.png';
import FormGroup from '../Form/FormGroup';
import { LandingPageContext } from '../../context/LandingPageContext';
import Layouts from '../Layout/index';

const Link = ScrollAnim.Link;
const { Title, Text } = Typography;
const { Content } = Layout;

const StyledBanner = Styled.div`
        background: url(${Banner});
        background-repeat: no-repeat;
        background-size: cover;   
        height: 100vh; 
`
const StyledTitleBox = Styled.div`
    text-align: center;
    padding: 70px 0px 30px 0px; 
    @media (max-width: 768px) {
        padding: 60px 0px 0px 0px;
    }
    h3.ant-typography {
        margin-bottom: 30px;
        position: relative;
        &:before {
            content: " ";
            position: absolute;
            background: #00a1de;
            width: 75px;
            height: 3px;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
        }        
    }
`
const Container = Styled(Content)`
    overflow: hidden;
    padding: 0px 50px 70px;
    @media (max-width: 768px) {
        padding: 0px 10px 40px;
    }
`
const Wrapper = Styled.div`
    background: url(${ContainerBg});
    background-repeat: no-repeat;
    background-size: 100% 1443px;
    background-position: top center;
    position: relative;
    overflow: hidden;
    min-height: 3541px;
    @media (max-width: 1024px) {
        background-size: 100% 1200px;
    }
    @media (max-width: 768px) {
        background-size: 100% 700px;
    }
    &:before {
        content: " ";
        position: absolute;
        background: url(${ContainerBg2});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 50%;
        background-position: top center;
        transform: translateY(100%);
        z-index: -1;
    }
`

const TitleBox = ({ email, title, subtitle }) => {
    const { t } = useTranslation();
    return (
        <>
            {email ? (
                <StyledTitleBox>
                    <Title level={3} type="black">
                        {title}
                    </Title>
                    <Text type="normal-color" style={{ textAlign: 'center' }}>
                        {subtitle}
                    </Text>
                </StyledTitleBox>
            ) : (
                <StyledTitleBox>
                    <Title level={3} type="black">
                        {t('language.Efun.Introduction.title')}
                    </Title>
                    <Text type="normal-color" style={{ textAlign: 'center' }}>
                    </Text>
                </StyledTitleBox>
            )}
        </>
    )
}

const EFun = () => {
    const { t } = useTranslation();
    const { onScrollEnterChange } = useContext(ScrollContext);
    const onEnterChange = (mode) => {
        onScrollEnterChange(mode === 'enter')
    }
    const { onLandingPage } = useContext(LandingPageContext);
    useEffect(() => {
        onLandingPage(false);
    })
    //const Logos = [Facebook, GoogleMarketPartner, LINE, TikTok, KOCHAVA, GoogleMarket, Yahoo, Apple];
    const Logos_v1 = [Facebook, GoogleMarketPartner, LINE, TikTok, GoogleMarket, Yahoo, Apple];
    const listLogos = Logos_v1.map((logo, index) =>
        <div key={index} className="partnerLogo">
            <img src={logo} width="100%" alt="logos" />
        </div>
    )
    return (
        <Layouts>
            <StyledBanner>
                <div className="hero">
                    <Title level={2} type="warning">
                        {t('language.Efun.banner.title')}
                    </Title>
                    <p>
                        {t('language.Efun.banner.subtitle')}
                    </p>
                    <Link to="contact"><Button size="default">{t('language.Efun.banner.button')}</Button></Link>
                </div>
                <Link duration={1500} to="page2"><div className="scrollPointer"></div></Link>
            </StyledBanner>
            <ScrollElement
                id="page2"
                onChange={({ mode }) => onEnterChange(mode)}
                playScale={0.1}>
                <Container className="eFunAboutTitle" style={{ background: '#f7f9fc' }}>
                    <TitleBox email={false} />
                    <div className="text">
                        <Text>{t('language.Efun.Introduction.content.0')}</Text><br />
                        <p style={{ textAlign: 'justify' }}>{t('language.Efun.Introduction.content.1')}</p>
                    </div>
                </Container>
                <Wrapper section1={true}>
                    <Container>
                        <div className="partnerLogoBox">
                            {listLogos}
                        </div>
                        <TitleBox email={true} title={t('language.Efun.Introduction.history.title')} subtitle="" />
                        <ul className="timeLine">
                            <li>
                                <div className="item left">
                                    <Title level={3} type="black" style={{ marginBottom: 0 }}>2015</Title>
                                    <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.2015.0')}</p>
                                    <div className="line">
                                        <Text>{t('language.Efun.Introduction.history.content.2015.1')}</Text>
                                        <div className="flex">
                                            <div className="itemImg">
                                                <img src={EFUN} alt="efun" width="100%" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="flex-right">
                                <div className="item right">
                                    <Title level={3} type="black" style={{ marginBottom: 0 }}>2016</Title>
                                    <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.2016.0')}</p>
                                    <div className="line">
                                        <Text>{t('language.Efun.Introduction.history.content.2016.1')}</Text>
                                        <div className="flex">
                                            <div className="itemImg">
                                                <img src={MyCard} alt="Mycard" width="100%" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="upThird">
                                <div className="item left">
                                    <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.20165.0')}</p>
                                    <div className="line">
                                        <Text>{t('language.Efun.Introduction.history.content.20165.1')}</Text>
                                        <div className="flex">
                                            <div className="itemImg">
                                                <img src={Read} alt="Read" width="100%" />
                                            </div>
                                            <div className="itemImg">
                                                <img src={Wisebirs} alt="Wisebirs" width="100%" />
                                            </div>
                                            <div className="itemImg">
                                                <img src={Adwitt} alt="Adwitt" width="100%" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <div className="upFive">
                                <li className="flex-right">
                                    <div className="item right">
                                        <Title level={3} type="black" style={{ marginBottom: 0 }}>2017</Title>
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.2017.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.2017.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImg">
                                                    <img src={GoogleMarketPartner} alt="GoogleMarketPartner" width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="upThird">
                                    <div className="item left">
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.20175.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.20175.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImgHeight">
                                                    <img src={KOCHAVA} alt="KOCHAVA" height="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-right upThird">
                                    <div className="item right">
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.2017half.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.2017half.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImg">
                                                    <img src={Gamer} alt="巴哈姆特" width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="item left">
                                        <Title level={3} type="black" style={{ marginBottom: 0 }}>2018</Title>
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.2018.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.2018.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImgHeight">
                                                    <img src={OpenChannel} alt="OpenChannel" height="100%" />
                                                </div>
                                                <div className="itemImgHeight">
                                                    <img src={OpenPoint} alt="OpenPoint" height="100%" />
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="itemImg">
                                                    <img src={GameBase} alt="遊戲基地" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={TimeReport} alt="自由時報" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={Udn} alt="udn" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={ChinaTimes} alt="中時電子報" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={AppleReport} alt="蘋果日報" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={ETToday} alt="東森新聞" width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-right">
                                    <div className="item right">
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.20185.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.20185.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImg">
                                                    <img src={Facebook} alt="Facebook" width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="item left">
                                        <Title level={3} type="black" style={{ marginBottom: 0 }}>2019</Title>
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.2019.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.2019.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImg">
                                                    <img src={Dcard} alt="Dcard" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={Twitch} alt="Twitch" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={CK} alt="卡提諾" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={QIY} alt="愛奇藝" width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-right upThird">
                                    <div className="item right">
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.20195.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.20195.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImg">
                                                    <img src={BlueStacks} alt="BlueStacks" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={Emulator} alt="夜神模擬器" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={ThunderEmulator} alt="雷電模擬器" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={QOO} alt="QooApp" width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="item left">
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.2019half.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.2019half.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImg">
                                                    <img src={Line} alt="LINE" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={TikTokLogo} alt="TikTok" width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="flex-right">
                                    <div className="item right">
                                        <Title level={3} type="black" style={{ marginBottom: 0 }}>2020</Title>
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.2020.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.2020.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImg">
                                                    <img src={GoogleMarketPartner} alt="GooglePartner" width="100%" />
                                                </div>
                                                <div className="itemImg">
                                                    <img src={EFunAwards} alt="EFun" width="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="item left">
                                        <p className="timeLineSubtitle">{t('language.Efun.Introduction.history.content.20205.0')}</p>
                                        <div className="line">
                                            <Text>{t('language.Efun.Introduction.history.content.20205.1')}</Text>
                                            <div className="flex">
                                                <div className="itemImgHeight">
                                                    <img src={AdHero} alt="AdHero" height="100%" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </Container>
                </Wrapper>
            </ScrollElement>
            <Container style={{ background: '#f7f9fc' }} id="contact">
                <TitleBox email={true} title={t('language.form.title')} subtitle={t('language.form.subtitle')} />
                <FormGroup />
            </Container>
        </Layouts>
    )
}

export default EFun;