import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Button, Layout, Table, Tabs, Typography } from 'antd';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import React, { useContext, useEffect, useState } from "react";
import { Translation, useTranslation } from 'react-i18next';
import Styled from "styled-components";
import { LandingPageContext } from '../../context/LandingPageContext';
import { ScrollContext } from '../../context/ScrollContext';
import Layouts from '../Layout/index';
import ModalRefund from '../Modal/ModalRefund';
import { LimitSale } from '../LimitSale';

const { Title, Text } = Typography;
const { Content } = Layout;
const { TabPane } = Tabs;

const Container = Styled(Content)`
    overflow: hidden;
    padding: 0px 50px 70px;
    @media (max-width: 768px) {
        padding: 0px 10px 40px;
    }
`
const StyledTitleBox = Styled.div`
    text-align: center;
    padding: 150px 0px 30px 0px; 
    @media (max-width: 768px) {
        padding: 60px 0px 0px 0px;
    }
    h1.ant-typography {
      @media(max-width: 767px) {
        font-size: 2rem;
        margin: 0.5em 0px;
      }
    }
    h2.ant-typography {
        margin: 30px 0px;
        position: relative;  
        font-size: 2rem;      
    }
`
const StyledTable = Styled(Table)`
    margin: 30px 0px 60px 0px;
    @media(max-width: 767px) {
      margin:  0px 0px 60px 0px;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: rgba(255, 255, 255, 0);
    }
    .ant-table-content {
      overflow-x: auto;
      overflow-y: hidden;
    }
    .ant-table-thead > tr > th {
        color: #595959;
        background: #e5f5fc;
        text-align: center;
    }
    .ant-table table {
        text-align: center;
    }
    button {
        padding: 7px 16px;
        margin-top: 10px;
        text-shadow: none;
        font-size: 14px;
    }
    .tdTitle {
        min-height: 90px;
        @media (max-width: 1025px) {
            min-height: 120px;
        }
    }
`
const TableTitleDiscount = ({title, disCount}) => {
  const { t } = useTranslation();
  switch(disCount) {
    case "FiveDiscountOff": return (
      <><span className="discount">{t('language.price.discount.0')}</span>{title}</>
    ) 
    case "TenDiscountOff": return (
      <><span className="discount">{t('language.price.discount.1')}</span>{title}</>
    ) 
    case "FifteenDiscountOff": return (
      <><span className="discount">{t('language.price.discount.2')}</span>{title}</>
    ) 
    default: return (
      <>{title}</>
    )
  }
};

const Price = () => {
  const { onScrollEnterChange } = useContext(ScrollContext);
  const onEnterChange = (mode) => { onScrollEnterChange(mode === 'enter')}
  const { onLandingPage } = useContext(LandingPageContext);
  const [refundModal, setRefundModal] = useState(false);
  const { t } = useTranslation();
  const [discountOff, setDiscountOff] = useState();
  const [professionalPrice, setProfessionalPrice] = useState("NT$ 80,000");
  const [enterprisePrice, setEnterprisePrice] = useState("NT$ 150,000");
  const [customizedSelectionPrice, setCustomizedSelectionPrice] = useState("NT$ 3,000");  
  const [professional, setProfessional] = useState("Purchase_1P");
  const [enterprise, setEnterprise] = useState("Purchase_1B");
  const [customizedSelection, setCustomizedSelection] = useState("Purchase_1F");
  useEffect(() => {
    onLandingPage(false);
  });
  const allArray = () => {
      const object = [];
      for(let i=0;i<29; i+=1 ) {
        object.push(i);
      }
      return object;
  }
  function columnsContext(text, record, index, targetObject) {
    const obj = {
      children: text,
      props: {},
    }; 
    const blueBackground = [1, 7, 12, 15, 20, 26];
    const err = allArray();  
    blueBackground.map(c => {
        if(index === c) {
          return (
            <>
              {obj.props = {  
                  style: { background: '#e5f5fc', fontWeight: 500, color: '#5a5a5a' }
              }}
            </>
          )          
        }
    }) 
    if(index === 4) {
      obj.props = {  style: { display: 'none' }}
    }  
    switch(targetObject.dataIndex){
      case 'planName':
        if (index === 0) {
          obj.children = <div className="tdTitle"><p style={{ fontSize: '14px', marginTop: '1em' }}>{text}</p></div>
        }
        if (index === 2) {
          obj.props.rowSpan = 2;
        }
        if (index === 3 && 4) {
          obj.props.rowSpan = 0;
        }       
      break;
      case 'Free':
        err.map(c => {
          if(blueBackground.includes(c) !== true) {
            if(index === c) {
              obj.children = <CloseCircleFilled style={{ fontSize:'20px',color: '#ff4d4f' }} />
            }
          }
        })
        if (index === 0) {
          obj.children = 
              <div className="tdTitle">
                <p style={{ fontSize: '19px', fontWeight: 500, color: 'rgb(89, 89, 89)', marginBottom: '10px' }}>{text}</p>
                <span style={{ fontSize: '14px' }}><Translation>{(t) => <>{t('language.price.table.offerContent')}</>}</Translation></span>
              </div>
        }
      break;  
      case 'profession':
        err.map(c => {
          if(blueBackground.includes(c) !== true) {
            if(index === c) {
              obj.children =  <><CheckCircleFilled style={{ fontSize:'20px',color: '#52c41a' }} /><br />{text}</>
            }           
          }
        })
        if (index === 0) {
          obj.children = 
          <>
              <div className="tdTitle">
                  <p style={{ fontSize: '19px', fontWeight: 500, color: 'rgb(89, 89, 89)', marginBottom: '10px' }}>{text}</p>
                  <span style={{ fontSize: '14px', textDecoration: 'line-through' }}><Translation>{(t) => <>NT$ 90,000／{t('language.price.table.month')}</>}</Translation></span>
              </div>
              <Button type="primary"><a className={professional} href="https://platform.goadhero.com/PaymentV2/Confirm?p=1001&d=1" target="_blank" rel="noopener noreferrer">{t('language.price.table.button')}</a></Button>
          </> 
        }
      break;
      case 'company':
        err.map(c => {
          if(blueBackground.includes(c) !== true) {
            if(index === c) {
              obj.children =  <><CheckCircleFilled style={{ fontSize:'20px',color: '#52c41a' }} /><br />{text}</>
            }           
          }
        })
        if (index === 0) {
          obj.children = 
          <>
              <div className="tdTitle">
                  <p style={{ fontSize: '19px', fontWeight: 500, color: 'rgb(89, 89, 89)', marginBottom: '10px' }}>{text}</p>
                  <span style={{ fontSize: '14px', textDecoration: 'line-through' }}><Translation>{(t) => <>NT$ 240,000／{t('language.price.table.month')}</>}</Translation></span>
              </div>
              <Button type="primary"><a className={enterprise} href="https://platform.goadhero.com/PaymentV2/Confirm?p=1002&d=1" target="_blank" rel="noopener noreferrer">{t('language.price.table.button')}</a></Button>
          </> 
        }        
      break;
      case 'feature':
        if (index === 0) {
          obj.children = 
          <>
            <div className="tdTitle">
              <p style={{ fontSize: '19px', fontWeight: 500, color: 'rgb(89, 89, 89)', marginBottom: '10px' }}>{text}</p>
              <span style={{ fontSize: '14px' }}><Translation>{(t) => <>{t('language.price.table.customizedSelectionContent')}</>}</Translation></span>
            </div>
            <Button type="primary"><a className={customizedSelection} href="https://platform.goadhero.com/PaymentV2/Confirm?p=9001&d=1" target="_blank" rel="noopener noreferrer">{t('language.price.table.button')}</a></Button>
          </> 
        }                
      break
    }
    return obj;
  }
  const columns = [
    {
      title:<Translation>{(t) => <>{t('language.price.table.plan')}</>}</Translation>,
      dataIndex: 'planName',
      render: (text, record, index) => {
        const targetObject = columns[0].method();
        return columnsContext(text, record, index, targetObject);
      },
      method: function () {
        return this;
      }
    },
    {
      title: <Translation>{(t) => <>{t('language.price.table.free')}</>}</Translation>,
      dataIndex: 'Free',
      render: (text, record, index) => {
        const targetObject = columns[1].method();
        return columnsContext(text, record, index, targetObject);
      },
      method: function () {
        return this;
      }   
    },
    {
      title:<TableTitleDiscount disCount={discountOff} title={<Translation>{(t) => <>{t('language.price.table.professional')}</>}</Translation>} />,
      dataIndex: 'profession',
      render: (text, record, index) => {
        const targetObject = columns[2].method();
        return columnsContext(text, record, index, targetObject);
      },
      method: function () {
        return this;
      } 
    },
    {
      title: <TableTitleDiscount disCount={discountOff} title={<Translation>{(t) => <>{t('language.price.table.enterprise')}</>}</Translation>} />,
      dataIndex: 'company',
      render: (text, record, index) => {
        const targetObject = columns[3].method();
        return columnsContext(text, record, index, targetObject);
      },
      method: function () {
        return this;
      }
    },
    {
      title: <TableTitleDiscount disCount={discountOff} title={<Translation>{(t) => <>{t('language.price.table.customizedSelection')}</>}</Translation>} />,
      dataIndex: 'feature',
      render: (text, record, index) => {
        const targetObject = columns[4].method();
        return columnsContext(text, record, index, targetObject);
      },
      method: function () {
        return this;
      }
    },
  ];
  const data = [
      {
        key: '1',
        planName: <Translation>{(t) => <>{t('language.price.table.price')}</>}</Translation>,
        Free: '$0',
        profession: <Translation>{(t) => <>{professionalPrice}／{t('language.price.table.month')}</>}</Translation>,
        company: <Translation>{(t) => <>{enterprisePrice}／{t('language.price.table.month')}</>}</Translation>,
        feature: <Translation>{(t) => <>{customizedSelectionPrice}{t('language.price.table.up')}／{t('language.price.table.month')}</>}</Translation>
      },
      {
        key: '2',
        planName: <Translation>{(t) => <>{t('language.price.table.marketInfo')}</>}</Translation>,
        Free: <Translation>{(t) => <>{t('language.price.table.free')}</>}</Translation>,
        profession: <Translation>{(t) => <>{t('language.price.table.professional')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.enterprise')}</>}</Translation>,
        feature: <Translation>{(t) => <>{t('language.price.table.customizedSelection')}</>}</Translation>
      },
      {
        key: '3',
        planName: <Translation>{(t) => <>{t('language.price.table.marketComparison')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>Facebook {t('language.price.table.products')}</>}</Translation>,
        company: <Translation>{(t) => <>Facebook {t('language.price.table.products')}</>}</Translation>,
        feature: <Translation>{(t) => 
                    <>
                      {t('language.price.table.SingleData')}{customizedSelectionPrice}<br />
                      Facebook {t('language.price.table.products')}<br />
                      {t('language.price.table.FacebookFree')}
                    </>
                }</Translation>
      },
      {
        key: '4',
        planName: <Translation>{(t) => <>{t('language.price.table.marketComparison')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>Google {t('language.price.table.products')}</>}</Translation>,
        company: <Translation>{(t) => <>Google {t('language.price.table.products')}</>}</Translation>,
        feature: <Translation>{(t) => 
                    <>
                      {t('language.price.table.SingleData')}{customizedSelectionPrice}<br />
                      Google {t('language.price.table.products')}<br />
                      {t('language.price.table.GoogleFree')}
                    </>
                }</Translation>
      },
      {
        key: '5',
      }, 
      {
        key: '6',
        planName: <Translation>{(t) => <>{t('language.price.table.Tracking')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>{}</>}</Translation>,
        company: <Translation>{(t) => <>{}</>}</Translation>,
        feature: <Translation>{(t) => <>{customizedSelectionPrice}<br />{}</>}</Translation>
      },      
      {
        key: '7',
        planName: <Translation>{(t) => <>{t('language.price.table.publicOpinionAnalysis')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>{t('language.price.table.publicOpinionAnalysisContent')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.publicOpinionAnalysisContent')}</>}</Translation>,
        feature: <Translation>{(t) => <>{customizedSelectionPrice}<br />{t('language.price.table.publicOpinionAnalysisContent')}</>}</Translation>
      },
      {
        key: '8',
        planName: <Translation>{(t) => <>{t('language.price.table.AIPrediction')}</>}</Translation>,
        Free: <Translation>{(t) => <>{t('language.price.table.free')}</>}</Translation>,
        profession: <Translation>{(t) => <>{t('language.price.table.professional')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.enterprise')}</>}</Translation>,
        feature: <Translation>{(t) => <>{t('language.price.table.customizedSelection')}</>}</Translation>
      },
      {
        key: '7',
        planName: <Translation>{(t) => <>{t('language.price.table.Top100Video')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <></>}</Translation>,
        company: <Translation>{(t) => <></>}</Translation>,
        feature: <Translation>{(t) => 
                    <>{customizedSelectionPrice}</>
                }</Translation>
      },
      {
        key: '8',
        planName: <Translation>{(t) => <>{t('language.price.table.Top100Image')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <></>}</Translation>,
        company: <Translation>{(t) => <></>}</Translation>,
        feature: <Translation>{(t) => 
                    <>{customizedSelectionPrice}</>
                }</Translation>
      },
      {
        key: '9',
        planName: <Translation>{(t) => <>競品素材追蹤</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <></>}</Translation>,
        company: <Translation>{(t) => <></>}</Translation>,
        feature: <Translation>{(t) => <>{customizedSelectionPrice}</>}</Translation>
      },
      {
        key: '10',
        planName: <Translation>{(t) => <>文案推薦</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>{}</>}</Translation>,
        company: <Translation>{(t) => <>{}</>}</Translation>,
        feature: <Translation>{(t) => <>{customizedSelectionPrice}<br />{}</>}</Translation>
      },
      {
        key: '11',
        planName: <Translation>{(t) => <>素材分析</>}</Translation>,
        Free: <Translation>{(t) => <>{t('language.price.table.free')}</>}</Translation>,
        profession: <Translation>{(t) => <>{t('language.price.table.professional')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.enterprise')}</>}</Translation>,
        feature: <Translation>{(t) => <>{t('language.price.table.customizedSelection')}</>}</Translation>
      },
      {
        key: '12',
        planName: <Translation>{(t) => <>{t('language.price.table.ImagePrediction')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>{t('language.price.table.ImagePerformanceContent.0')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.ImagePerformanceContent.1')}</>}</Translation>,
        feature: <Translation>{(t) => <>{customizedSelectionPrice}<br />{t('language.price.table.ImagePerformanceContent.2')}</>}</Translation>
      },
      {
        key: '13',
      planName: <Translation>{(t) => <>{t('language.price.table.VideoPrediction')}(Beta)</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>{t('language.price.table.VideoPerformanceContent.0')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.VideoPerformanceContent.1')}</>}</Translation>,
        feature: <Translation>{(t) => <>{customizedSelectionPrice}<br />{t('language.price.table.VideoPerformanceContent.2')}</>}</Translation>
      },      
      {
        key: '14',
        planName: <Translation>{(t) => <>Google{t('language.price.table.AdsAnalysis')}</>}</Translation>,
        Free: <Translation>{(t) => <>{t('language.price.table.free')}</>}</Translation>,
        profession: <Translation>{(t) => <>{t('language.price.table.professional')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.enterprise')}</>}</Translation>,
        feature: <Translation>{(t) => <>{t('language.price.table.customizedSelection')}</>}</Translation>
      },
      {
        key: '15',
        planName: <Translation>{(t) => <>{t('language.price.table.AdAccountDiagnostics')}</>}</Translation>,
        Free: '',
        profession: '',
        company: '',
        feature: <>{customizedSelectionPrice}</>
      },
      {
        key: '16',
        planName: <Translation>{(t) => <>{t('language.price.table.ImagePerformanceAnalysis')}</>}</Translation>,
        Free: '',
        profession: '',
        company: '',
        feature: <>{customizedSelectionPrice}</>
      },
      {
        key: '18',
        planName: <Translation>{(t) => <>{t('language.price.table.AppEventsRecommendation')}</>}</Translation>,
        Free: '',
        profession: '',
        company: '',
        feature: <>{customizedSelectionPrice}</>
      },
      {
        key: '19',
        planName: <Translation>{(t) => <>{t('language.price.table.AdAccountSettings')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>{t('language.price.table.AdAccountGoogleContent.0')}{customizedSelectionPrice}／{t('language.price.table.month')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.AdAccountGoogleContent.1')}{customizedSelectionPrice}／{t('language.price.table.month')}</>}</Translation>,
        feature: <Translation>{(t) => <>{t('language.price.table.AdAccountGoogleContent.2')}{customizedSelectionPrice}／{t('language.price.table.month')}</>}</Translation>
      },
      {
        key: '20',
        planName: <Translation>{(t) => <>Facebook{t('language.price.table.AdsAnalysis')}</>}</Translation>,
        Free: <Translation>{(t) => <>{t('language.price.table.free')}</>}</Translation>,
        profession: <Translation>{(t) => <>{t('language.price.table.professional')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.enterprise')}</>}</Translation>,
        feature: <Translation>{(t) => <>{t('language.price.table.customizedSelection')}</>}</Translation>
      },
      {
        key: '21',
        planName: <Translation>{(t) => <>{t('language.price.table.AdAccountDiagnostics')}</>}</Translation>,
        Free: '',
        profession: '',
        company: '',
        feature: <>{customizedSelectionPrice}</>
      },
      {
        key: '22',
        planName: <Translation>{(t) => <>{t('language.price.table.ImagePerformanceAnalysis')}</>}</Translation>,
        Free: '',
        profession: '',
        company: '',
        feature: <>{customizedSelectionPrice}</>
      },
      {
        key: '24',
        planName: <Translation>{(t) => <>客戶名單管理</>}</Translation>,
        Free: '',
        profession: '',
        company:'',
        feature: <>{customizedSelectionPrice}</>
      }, 
      {
        key: '25',
        planName: <Translation>{(t) => <>廣告受眾推薦</>}</Translation>,
        Free: '',
        profession: '',
        company:'',
        feature: <>{customizedSelectionPrice}</>
      },       
      {
        key: '26',
        planName: <Translation>{(t) => <>{t('language.price.table.AdAccountSettings')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>{t('language.price.table.AdAccountFacebookContent.0')}{customizedSelectionPrice}／{t('language.price.table.month')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.AdAccountFacebookContent.1')}{customizedSelectionPrice}／{t('language.price.table.month')}</>}</Translation>,
        feature: <Translation>{(t) => <>{t('language.price.table.AdAccountFacebookContent.2')}{customizedSelectionPrice}／{t('language.price.table.month')}</>}</Translation>
      },
      {
        key: '27',
        planName: <Translation>{(t) => <>{t('language.price.table.other')}</>}</Translation>,
        Free: <Translation>{(t) => <>{t('language.price.table.free')}</>}</Translation>,
        profession: <Translation>{(t) => <>{t('language.price.table.professional')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.enterprise')}</>}</Translation>,
        feature: <Translation>{(t) => <>{t('language.price.table.customizedSelection')}</>}</Translation>
      },
      {
        key: '28',
        planName: <Translation>{(t) => <>{t('language.price.table.LINEAuto-Report')}</>}</Translation>,
        Free: '',
        profession: <Translation>{(t) => <>{t('language.price.table.LINEAutoContent')}{customizedSelectionPrice}／{t('language.price.table.month')}</>}</Translation>,
        company: <Translation>{(t) => <>{t('language.price.table.LINEAutoContent')}{customizedSelectionPrice}／{t('language.price.table.month')}</>}</Translation>,
        feature: <Translation>{(t) => <>{customizedSelectionPrice}<br />{t('language.price.table.LINEAutoContent')}{customizedSelectionPrice}／{t('language.price.table.month')}</>}</Translation>
      }
    ];
  const [activeKey, setActiveKey] = useState("1");
  const onChange = (activeKey) => {
    setActiveKey(activeKey)
    switch(activeKey) {
      case "1":
        setDiscountOff();
        setProfessionalPrice("NT$ 80,000");
        setEnterprisePrice("NT$ 150,000");
        setCustomizedSelectionPrice("NT$ 3,000");
        setProfessional("Purchase_1P");
        setEnterprise("Purchase_1B");
        setCustomizedSelection("Purchase_1F");        
      break
      case "2":
        setDiscountOff("FiveDiscountOff");
        setProfessionalPrice("NT$ 76,000");
        setEnterprisePrice("NT$ 142,500");
        setCustomizedSelectionPrice("NT$ 2,850");
        setProfessional("Purchase_3P");
        setEnterprise("Purchase_3B");
        setCustomizedSelection("Purchase_3F");        
      break
      case "3":
        setDiscountOff("TenDiscountOff");
        setProfessionalPrice("NT$ 72,100");
        setEnterprisePrice("NT$ 135,000");
        setCustomizedSelectionPrice("NT$ 2,700");
        setProfessional("Purchase_6P");
        setEnterprise("Purchase_6B");
        setCustomizedSelection("Purchase_6F");
      break
      case "4":
        setDiscountOff("FifteenDiscountOff");
        setProfessionalPrice("NT$ 68,000");
        setEnterprisePrice("NT$ 127,500");
        setCustomizedSelectionPrice("NT$ 2,550");
        setProfessional("Purchase_12P");
        setEnterprise("Purchase_12B");
        setCustomizedSelection("Purchase_12F");  
      break       
      default: 
        console.log('lose activeKey');     
    }   
  }

    return (
      <Layouts>
      <ModalRefund refundModal={refundModal} setRefundModal={() => setRefundModal(false)}/>
      <LimitSale />
        <ScrollElement
        id="page"
        onChange={({ mode }) => onEnterChange(mode)}
        playScale={0.1}>
            <Container>
                <StyledTitleBox> 
                    <Title level={1}>{t('language.price.title')}</Title>
                    <Text>{t('language.price.subtitle')}</Text>
                </StyledTitleBox> 
                <Tabs 
                centered="true" 
                className="ant-tabs-centered" 
                onChange={onChange}
                activeKey={activeKey}> 
                  <TabPane tab={t('language.price.month.0')} key="1">
                    <StyledTable columns={columns} dataSource={data} tableLayout="fixed" scroll="true" bordered="false" pagination={false} />
                  </TabPane>
                  <TabPane tab={t('language.price.month.1')} key="2">
                    <StyledTable columns={columns} dataSource={data} tableLayout="fixed" scroll="true" bordered="false" pagination={false} />
                  </TabPane>
                  <TabPane tab={t('language.price.month.2')} key="3">
                    <StyledTable columns={columns} dataSource={data} tableLayout="fixed" scroll="true" bordered="false" pagination={false} />
                  </TabPane>
                  <TabPane tab={t('language.price.month.3')} key="4">
                    <StyledTable columns={columns} dataSource={data} tableLayout="fixed" scroll="true" bordered="false" pagination={false} />
                  </TabPane>  
                </Tabs>
                <div className="recommendBox" style={{ margin: '0px auto 0px' }}>
                    <Button type="primary"><a className="Registered" href="https://platform.goadhero.com/ " target="_blank" rel="noopener noreferrer">{t('language.price.signUp')}</a></Button>
                    <div style={{ marginTop: '8px' }}><Button type="danger" className="refundText" onClick={() => setRefundModal(true)}>{t('language.about.Introduction.content.recommend.button.1')}</Button></div>
                </div>
            </Container>
        </ScrollElement>
        </Layouts>
    )
}

export default Price;