import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts'

const ApexChart = ({ seriesData, stateType }) => {
    const [deviceSize, setDeviceSize] = useState(180);
    const [deviceHeight, setDeviceHeight] = useState(430);
    useEffect(() => {
        if (window.innerWidth >= 1200) {
            setDeviceSize(180);
            setDeviceHeight(430)
        }
        if (window.innerWidth < 1199) {
            setDeviceSize(100);
            setDeviceHeight(380);
        }
    },[deviceSize, deviceHeight])
      
    const state = {
        series: [{
            name: '本素材色彩使用尚有調整空間',
            data: seriesData,
        }],
        options: {
            dataLabels: {
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                },
            },
            chart: {
            fontFamily: 'Noto Sans TC',
            foreColor: "#006400",
            type: 'radar',
            height: deviceHeight,
            fontSize: '36px',
            toolbar: {
                    show: false
                },
            },
            grid: {
                row: { colors: ["transparent", "transparent"], opacity: 0.2 },
                borderColor: "#f1f3fa"
            },
            title: {
            text: ''
            },
            markers: {
                strokeColor: '#00a1de',
                size: 4
            },
            fill: {
                opacity: 0
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: '14px',
                    },
                    offsetY: 5,
                },
                categories: ['色彩協調性', '色彩亮度/對比', '畫面聚焦度', '色彩飽和度', '畫面構圖', '色彩使用性']
            },
            stroke: {
                curve: 'smooth',
                width: 3,     
            },
            tooltip: {
                theme: 'dark',
                custom: function({ dataPointIndex, w }) {
                    const hoverTitle = w.globals.labels[dataPointIndex];
                    if (hoverTitle === '色彩協調性') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '畫面中色彩搭配的和諧程度，<br>此項分數為實際畫面之色彩協調分數與最佳建議值間的差異，<br>太協調或不協調均影響此項分數表現。' +
                            "</span>" +
                            "</div>"                            
                        )
                    }
                    if (hoverTitle === '色彩亮度/對比') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '畫面中最亮與最暗像素之間的差異，<br>差異越大表示畫面對比越高，<br>此項分數為實際畫面之亮度對比與最佳建議值間的差異，<br>亮度/對比太高或太低均影響此項分數表現。' +
                            "</span>" +
                            "</div>"   
                        )
                    }
                    if(hoverTitle === '畫面聚焦度') {
                        return (
                                '<div class="arrow_box">' +
                                "<span>" +
                                '畫面中元素之聚焦程度，<br>此項分數為實際畫面之聚焦分數與最佳建議值間的差異。' +
                                "</span>" +
                                "</div>"   
                        )
                    }
                    if(hoverTitle === '色彩飽和度') {
                        return (
                                '<div class="arrow_box">' +
                                "<span>" +
                                '畫面中彩度與明度的比值，<br>為色彩的鮮豔程度，<br>此項分數為實際畫面之色彩飽和度與最佳建議值間的差異，<br>色彩太鮮艷或太不鮮豔均影響此項分數表現。' +
                                "</span>" +
                                "</div>"   
                        )
                    }
                    if(hoverTitle === '畫面構圖') {
                        return (
                                '<div class="arrow_box">' +
                                "<span>" +
                                '畫面中物件的主從關係，<br>物件被凸顯的程度，<br>此項分數為實際畫面之構圖分數與最佳建議值間的差異。' +
                                "</span>" +
                                "</div>"   
                        )
                    }
                    if(hoverTitle === '色彩使用性') {
                        return (
                                '<div class="arrow_box">' +
                                "<span>" +
                                '畫面中使用色調之多元性，<br>此項分數為實際畫面之色彩使用分數與最佳建議值間的差異。' +
                                "</span>" +
                                "</div>"   
                        )
                    }
                }
            },
            yaxis: {
                tickAmount: 8,
                min: 0,
                max: 100,
                labels: {
                    style: {
                        fontSize: '14px',
                    },
                    formatter: function (value) {
                    //     if (value === 0) {
                    //         return "高";
                    //     }
                    //     if (value === 100) {
                    //         return "低";
                    //     }
                    //     return "";
                    }
                }
            },
            legend: {
                show: false
            },
            plotOptions: {
                radar: {
                    size: deviceSize,
                    offsetX: 0,
                    offsetY: 0,
                    polygons: {
                        strokeColors: ["#a1f7a1", "#c4fca8", "#e3ffb7", "#fcfedb", "#fff5b2", "#ffe2a9","#ffa48b", "#fa7f7a"],
                        connectorColors: ["#ffffff","#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff","#ffffff", "#ffffff", "#ffffff"],
                        strokeWidth: 1,
                        fill: {
                            colors: [ "#a1f7a1", "#c4fca8", "#e3ffb7", "#fcfedb", "#fff5b2", "#ffe2a9","#ffa48b", "#fa7f7a"]
                        }
                    }
                }
            },
        }
    }
    const viedoState = {
        series: [{
            name: '本素材色彩使用尚有調整空間',
            data: seriesData,
        }],
        options: {
            dataLabels: {
                style: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                },
            },
            chart: {
            fontFamily: 'Noto Sans TC',
            foreColor: "#006400",
            type: 'radar',
            height: deviceHeight,
            fontSize: '36px',
            toolbar: {
                    show: false
                },
            },
            grid: {
                row: { colors: ["transparent", "transparent"], opacity: 0.2 },
                borderColor: "#f1f3fa"
            },
            title: {
            text: ''
            },
            markers: {
                strokeColor: '#00a1de',
                size: 4,
            },
            fill: {
                opacity: 0
            },
            xaxis: {
                labels: {
                    style: {
                        fontSize: '14px',
                    },
                    offsetY: 5,
                },
                categories: [
                "開頭吸引",
                "特寫強調",
                "畫面配速",
                "氣氛營造",
                "文字資訊",
                "字卡比例",
                "結尾感受",
                "音樂效果"]
            },
            stroke: {
                curve: 'smooth',
                width: 3,     
            },
            tooltip: {
                theme: 'dark',
                custom: function({ dataPointIndex, w }) {
                    const hoverTitle = w.globals.labels[dataPointIndex];
                    if (hoverTitle === '開頭吸引') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '針對影片前幾秒的內容進行分析，<br>良好的開頭設計將能引起觀眾對於影片內容的觀看興趣，<br>提高觀眾觀看影片的機率。' +
                            "</span>" +
                            "</div>"                            
                        )
                    }
                    if (hoverTitle === '特寫強調') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '分析整體影片中所使用的鏡頭類型及鏡頭使用頻率，<br>適當的使用特寫鏡頭可以讓觀眾更加融入影片情境。' +
                            "</span>" +
                            "</div>"                            
                        )
                    }
                    if (hoverTitle === '畫面配速') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '各個場景的持續時間，<br>適當的畫面配速能加強影片的戲劇張力，<br>有效提高觀眾在觀看影片時的注意力，<br>過短或過長的配速反而會降低觀眾對於影片的興趣程度。' +
                            "</span>" +
                            "</div>"                            
                        )
                    }
                    if (hoverTitle === '氣氛營造') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '分析畫面片段中的影音元素呈現方式及出現的物件，<br>都會影響到影片帶給觀眾的氣氛，<br>適當的氣氛營造，<br>能加強觀眾對影片內容的注意力，亦能增加影片的觀看留存率。' +
                            "</span>" +
                            "</div>"                            
                        )
                    }
                    if (hoverTitle === '文字資訊') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '在片段中提供的資訊多寡，<br>偵測到文字時才會進行分析。<br>適當的文字資訊可以為觀眾加深資訊的重要性，<br>有助於加強觀眾對於影片的印象，提升觀眾的影片感受度。' +
                            "</span>" +
                            "</div>"                            
                        )
                    }
                    if (hoverTitle === '字卡比例') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '文字資訊在畫面中所佔的比例多寡，<br>適當的加入文字資訊或字卡能促使觀眾融入影片情境、加強觀眾的注意力。' +
                            "</span>" +
                            "</div>"                            
                        )
                    }
                    if (hoverTitle === '結尾感受') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '觀眾對於影片結尾的情緒感受程度，<br>設計適當的影片結尾可以加強觀眾對於影片整體感受以及加深觀看影片後的行為模式。' +
                            "</span>" +
                            "</div>"                            
                        )
                    }
                    if (hoverTitle === '音樂效果') {
                        return (
                            '<div class="arrow_box">' +
                            "<span>" +
                            '音樂設計造成觀眾觀看後的行為引導程度，<br>能適當的引導觀眾釋放在影片中所累積的情緒，<br>亦能提升觀眾在觀看影片後產生的期望行為。' +
                            "</span>" +
                            "</div>"                            
                        )
                    }
                }
            },
            yaxis: {
                tickAmount: 8,
                min: 0,
                max: 100,
                labels: {
                    style: {
                        fontSize: '14px',
                    },
                    formatter: function (value) {
                    //     if (value === 0) {
                    //         return "高";
                    //     }
                    //     if (value === 100) {
                    //         return "低";
                    //     }
                    //     return "";
                    }
                }
            },
            legend: {
                show: false
            },
            plotOptions: {
                radar: {
                    size: deviceSize,
                    offsetX: 0,
                    offsetY: 0,
                    polygons: {
                        strokeColors: ["#a1f7a1", "#c4fca8", "#e3ffb7", "#fcfedb", "#fff5b2", "#ffe2a9","#ffa48b", "#fa7f7a"],
                        connectorColors: ["#ffffff","#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff","#ffffff", "#ffffff", "#ffffff"],
                        strokeWidth: 1,
                        fill: {
                            colors: [ "#a1f7a1", "#c4fca8", "#e3ffb7", "#fcfedb", "#fff5b2", "#ffe2a9","#ffa48b", "#fa7f7a"]
                        }
                    }
                }
            },
        }        
    }
    return (
        <>
        <Chart className="radarChartMeasuring" 
        options={stateType? state.options : viedoState.options} 
        series={stateType? state.series : viedoState.series} type="radar" height={deviceHeight} />
        </>
    )
}

export default ApexChart;