import { Col, Drawer, Layout, Menu, Row, Select } from 'antd';
import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import Styled from 'styled-components';
import { I18nContext } from '../../context/I18nContext';
import { ScrollContext } from '../../context/ScrollContext';
import { LandingPageContext } from '../../context/LandingPageContext';
import { CurrentLocalContext } from '../../context/CurrentLocalContext';
import LogoLight from '../../static/logo-light.png';
import { KoreaSvg, TaiwanSvg, UsaSvg, JapanSvg } from '../Svg/Country';


const { Option } = Select;


const StyledUl = Styled(Menu)`
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0);
    border-bottom: none;
`;
const StyledHeader = Styled(Layout.Header)`
&.ant-layout-header{
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 0 20px !important;
  line-height: 48px;
  height: auto;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
  .ant-row {
    width: 100%;
  }
}
`;

const StyledImage = Styled.div`
  width: 210px;
  @media (max-width: 1024px) {
    width: 140px;
  }
`;

const MenuLink = ({ mode, style, onClick }) => {
  const { t } = useTranslation();
  const { changeRoute, isCurrent } = useContext(CurrentLocalContext);
  const items = ["about", "price", "eFun"];
  const listItem = items.map((item, index) => 
    <Menu.Item key={item}>
      <Link to={'/' + item} onClick={onClick}>
        {t('language.header.'+ (index+1))}
      </Link>
    </Menu.Item>    
  )
  return (
    <StyledUl
    onClick={(params) => changeRoute(params.key)}
    selectedKeys={isCurrent}
    mode={mode}
    style={style}
  >
    <Menu.Item key="index">
      <Link to="/" onClick={onClick}>
        {t('language.header.0')}
      </Link>
    </Menu.Item>
    {listItem}
    <Menu.Item key="outerLink">
      <a href="https://platform.goadhero.com/" target="_blank" className="singInLink" rel="noopener noreferrer">
        {t('language.header.4')}
      </a>
    </Menu.Item>    
    </StyledUl>
  )
}


const Header = () => {
  const { isFirstScreen } = useContext(ScrollContext);
  const { isLandingPage } = useContext(LandingPageContext);
  const [ visible, setVisible ] = useState(false);
  const { changeLocale, currentLocale } = useContext(I18nContext);
  const headerClassName = classNames({
    'navChangeColor': !isFirstScreen,
  });
  const onClose = () => {
    setVisible(false);
    document.documentElement.scrollTop = 0;
  };
  return (
  
        <>
      <StyledHeader className={headerClassName}>
        <Row>
          <Col xs={12} md={4}>
            <a href="/">
              <StyledImage>
                <img src={LogoLight} alt="logo" width="100%" />
              </StyledImage>
            </a>
          </Col>
          <Col xs={12} md={20}>
            <Row>
              <Col xs={0} md={0} lg={0} xl={22}>
                <MenuLink mode="horizontal" style={{display: 'flex',justifyContent: 'flex-end'}} onClick={onClose}/>
              </Col>
              <Col xs={8} md={18} lg={20} xl={0}>
                <div className="menu">
                  <label htmlFor="trigger">
                    <input  id="trigger" type="checkbox" name="trigger" className="menu-checkbox"  checked={visible} onChange={(e) => setVisible(e.target.checked)} />
                    <div className="hamburger">
                      <i></i>
                    </div>
                  </label>
                </div>
                <Drawer
                  title=" "
                  placement={'top'}
                  closable={false}
                  onClose={onClose}
                  visible={visible}
                  key={'top'}
                  className="menuBar"
                  height={390}
                >
                    <MenuLink mode="vertical" onClick={onClose} />
                </Drawer>
              </Col>
              <Col xs={16} md={6} lg={4} xl={2} style={{
                    textAlign: 'center'
                  }}>
                <Select
                  defaultValue={currentLocale}
                  onChange={(e) => changeLocale(e)}
                  className="LanSelect"
                  disabled={isLandingPage}
                >
                  <Option value="繁體中文">
                    <div style={{ display: 'flex' }}><TaiwanSvg />繁體中文</div>
                  </Option>
                  <Option value="한국어"><div style={{ display: 'flex' }}><KoreaSvg /> 한국어</div></Option>
                  <Option value="English"><div style={{ display: 'flex' }}><UsaSvg />English</div></Option>
                  <Option value="日本語"><div style={{ display: 'flex' }}><JapanSvg />日本語</div></Option>
                </Select>
              </Col>
            </Row>
            
          </Col>
        </Row>

      </StyledHeader>
    </>
  );
};

export default Header;
