import React, { useState } from 'react';

export const LandingPageContext = React.createContext({
    isLandingPage: false,
    onLandingPage: () => {},
    isVideoLoad: true,
    onLoadding: () => {}
})

const LandingPageProvider = ({ children }) => {
    const [isLandingPageState, setIsLandingPageState] = useState(false);
    const [isVideoLoadState, setIsVideoLoadState] = useState(true);
    const onLoadding = (status) => {
        setIsVideoLoadState(status);
    }    
    const onLandingPage = (status) => {
        setIsLandingPageState(status);
    }
    return (
        <LandingPageContext.Provider value={{
            isLandingPage: isLandingPageState,
            isVideoLoad: isVideoLoadState,
            onLandingPage,
            onLoadding
            }}>
            {children}
        </LandingPageContext.Provider>
    )
}

export default LandingPageProvider;