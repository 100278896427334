import { Layout, Typography } from 'antd';
import React, { useContext, useEffect } from "react";
import Styled from "styled-components";
import { ScrollContext } from '../../context/ScrollContext';
import Layouts from '../Layout/index';
import { useTranslation } from 'react-i18next';
const { Title } = Typography;
const { Content } = Layout;

const Container = Styled(Content)`
    overflow: hidden;
    padding: 100px 70px 50px 70px;
    text-align: justify;
    @media (max-width: 768px) {
        padding: 60px 5%;
    }
`

const Privacy = () => {
    const { t } = useTranslation();
    const { onScrollEnterChange } = useContext(ScrollContext);
    useEffect(() => {
        onScrollEnterChange('enter')
      });
    return (
        <Layouts>
            <Container>
                <Title level={2}>{t('language.privacy.title')}</Title>
                                <p>{t('language.privacy.subtitle')}<br />
                                {t('language.privacy.context')}
                                </p>
                                <p><b>{t('language.privacy.list1.title')}</b><br />
                                <span dangerouslySetInnerHTML={{__html: t('language.privacy.list1.context')}} />
                                </p>
                                <p><b>{t('language.privacy.list2.title')}</b><br /></p>
                                <p><b>{t('language.privacy.list2.subtitle1.title')}</b><br />
                                <span dangerouslySetInnerHTML={{__html: t('language.privacy.list2.subtitle1.context')}} />
                                </p>
                                <p><b>{t('language.privacy.list2.subtitle2.title')}</b><br />
                                {t('language.privacy.list2.subtitle2.context')}
                                </p>
                                <span dangerouslySetInnerHTML={{__html: t('language.privacy.list2.subtitle2.subList')}} />
                                <p><b>{t('language.privacy.list2.subtitle3.title')}</b></p>
                                <span dangerouslySetInnerHTML={{__html: t('language.privacy.list2.subtitle3.subList')}} />
                                <p><b>{t('language.privacy.list2.subtitle4.title')}</b></p>
                                <span dangerouslySetInnerHTML={{__html: t('language.privacy.list2.subtitle4.subList')}} />
                                <p><b>{t('language.privacy.list2.subtitle5.title')}</b></p>
                                <p>{t('language.privacy.list2.subtitle5.context')}</p>
                                <p><b>{t('language.privacy.list2.subtitle6.title')}</b></p>
                                <p>{t('language.privacy.list2.subtitle6.context')}</p>
                                <p><b>{t('language.privacy.list2.subtitle7.title')}</b></p>
                                <p>{t('language.privacy.list2.subtitle7.context')}</p>
                                <p><b>{t('language.privacy.list2.subtitle8.title')}</b></p>
                                <p>{t('language.privacy.list2.subtitle8.context')}</p>
                                <p><b>{t('language.privacy.list2.subtitle9.title')}</b></p>
                                <p dangerouslySetInnerHTML={{__html: t('language.privacy.list2.subtitle9.context')}} />
                                <p><b>{t('language.privacy.list2.subtitle10.title')}</b></p>
                                <p>{t('language.privacy.list2.subtitle10.context')}</p>
                                <p>{t('language.terms.telephone')}：<a href="tel:+886-2-2788-1111">02-2788-1111</a></p>
                                <p>{t('language.terms.email')}：<a href="mailto:service@goadhero.com">service@goadhero.com</a></p>
 
            </Container>
        </Layouts>
    )
}

export default Privacy;