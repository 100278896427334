import { faAngleLeft, faAngleRight, faCommentDots, faImages, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Layout, Modal, Row, Typography } from 'antd';
import ScrollAnim from 'rc-scroll-anim';
import ScrollElement from 'rc-scroll-anim/lib/ScrollElement';
import TweenOne from 'rc-tween-one';
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import Styled from 'styled-components';
import { LandingPageContext } from '../../context/LandingPageContext';
import { ScrollContext } from '../../context/ScrollContext';
import AIvideo from '../../static/landing/AIvideo.mp4';
import Mp4Desktop from '../../static/landing/banner.mp4';
import Celebrity2 from '../../static/landing/Celebrity/harris.png';
import Celebrity1 from '../../static/landing/Celebrity/witness.png';
import AD1 from '../../static/landing/Ecommerce/ad1.png';
import AD2 from '../../static/landing/Ecommerce/ad2.png';
import AD3 from '../../static/landing/Ecommerce/ad3.png';
import BR1 from '../../static/landing/Ecommerce/br1.jpg';
import BR10 from '../../static/landing/Ecommerce/br10.jpg';
import BR11 from '../../static/landing/Ecommerce/br11.jpg';
import BR13 from '../../static/landing/Ecommerce/br12.jpg';
import BR12 from '../../static/landing/Ecommerce/br13.jpg';
import BR14 from '../../static/landing/Ecommerce/br14.jpg';
import BR15 from '../../static/landing/Ecommerce/br15.jpg';
import BR2 from '../../static/landing/Ecommerce/br2.jpg';
import BR3 from '../../static/landing/Ecommerce/br3.jpg';
import BR4 from '../../static/landing/Ecommerce/br4.jpg';
import BR5 from '../../static/landing/Ecommerce/br5.jpg';
import BR6 from '../../static/landing/Ecommerce/br6.jpg';
import BR7 from '../../static/landing/Ecommerce/br7.jpg';
import BR8 from '../../static/landing/Ecommerce/br8.jpg';
import BR9 from '../../static/landing/Ecommerce/br9.jpg';
import BRVideo1 from '../../static/landing/Ecommerce/brVideo1.png';
import BRVideo2 from '../../static/landing/Ecommerce/brVideo2.png';
import BRVideo3 from '../../static/landing/Ecommerce/brVideo3.png';
import BRVideo4 from '../../static/landing/Ecommerce/brVideo4.png';
import BRVideo5 from '../../static/landing/Ecommerce/brVideo5.png';
import BRVideo6 from '../../static/landing/Ecommerce/brVideo6.png';
import BRVideo7 from '../../static/landing/Ecommerce/brVideo7.png';
import BRVideo8 from '../../static/landing/Ecommerce/brVideo8.png';
import BRVideo9 from '../../static/landing/Ecommerce/brVideo9.png';
import EC1 from '../../static/landing/Ecommerce/ecThumbnail1.jpg';
import EC10 from '../../static/landing/Ecommerce/ecThumbnail10.jpg';
import EC11 from '../../static/landing/Ecommerce/ecThumbnail11.jpg';
import EC12 from '../../static/landing/Ecommerce/ecThumbnail12.jpg';
import EC13 from '../../static/landing/Ecommerce/ecThumbnail13.jpg';
import EC14 from '../../static/landing/Ecommerce/ecThumbnail14.jpg';
import EC15 from '../../static/landing/Ecommerce/ecThumbnail15.jpg';
import EC16 from '../../static/landing/Ecommerce/ecThumbnail16.jpg';
import EC17 from '../../static/landing/Ecommerce/ecThumbnail17.jpg';
import EC18 from '../../static/landing/Ecommerce/ecThumbnail18.jpg';
import EC2 from '../../static/landing/Ecommerce/ecThumbnail2.jpg';
import EC3 from '../../static/landing/Ecommerce/ecThumbnail3.jpg';
import EC4 from '../../static/landing/Ecommerce/ecThumbnail4.jpg';
import EC5 from '../../static/landing/Ecommerce/ecThumbnail5.jpg';
import EC6 from '../../static/landing/Ecommerce/ecThumbnail6.jpg';
import EC7 from '../../static/landing/Ecommerce/ecThumbnail7.jpg';
import EC8 from '../../static/landing/Ecommerce/ecThumbnail8.jpg';
import EC9 from '../../static/landing/Ecommerce/ecThumbnail9.jpg';
import ECVideo1 from '../../static/landing/Ecommerce/ecVideo1.png';
import ECVideo2 from '../../static/landing/Ecommerce/ecVideo2.png';
import ECVideo3 from '../../static/landing/Ecommerce/ecVideo3.png';
import ECVideo4 from '../../static/landing/Ecommerce/ecVideo4.png';
import ECVideo5 from '../../static/landing/Ecommerce/ecVideo5.png';
import ECVideo6 from '../../static/landing/Ecommerce/ecVideo6.png';
import ECVideo7 from '../../static/landing/Ecommerce/ecVideo7.png';
import ECVideo8 from '../../static/landing/Ecommerce/ecVideo8.png';
import ECVideo9 from '../../static/landing/Ecommerce/ecVideo9.png';
import GAME1 from '../../static/landing/Ecommerce/game1.jpg';
import GAME10 from '../../static/landing/Ecommerce/game10.jpg';
import GAME11 from '../../static/landing/Ecommerce/game11.jpg';
import GAME13 from '../../static/landing/Ecommerce/game12.jpg';
import GAME12 from '../../static/landing/Ecommerce/game13.jpg';
import GAME14 from '../../static/landing/Ecommerce/game14.jpg';
import Game15 from '../../static/landing/Ecommerce/game15.png';
import GAME2 from '../../static/landing/Ecommerce/game2.jpg';
import GAME3 from '../../static/landing/Ecommerce/game3.jpg';
import GAME4 from '../../static/landing/Ecommerce/game4.jpg';
import GAME5 from '../../static/landing/Ecommerce/game5.jpg';
import GAME6 from '../../static/landing/Ecommerce/game6.jpg';
import GAME7 from '../../static/landing/Ecommerce/game7.jpg';
import GAME8 from '../../static/landing/Ecommerce/game8.jpg';
import GAME9 from '../../static/landing/Ecommerce/game9.jpg';
import GAMEVideo1 from '../../static/landing/Ecommerce/gameVideo1.png';
import GAMEVideo2 from '../../static/landing/Ecommerce/gameVideo2.png';
import GAMEVideo3 from '../../static/landing/Ecommerce/gameVideo3.png';
import GAMEVideo4 from '../../static/landing/Ecommerce/gameVideo4.png';
import GAMEVideo5 from '../../static/landing/Ecommerce/gameVideo5.png';
import GAMEVideo6 from '../../static/landing/Ecommerce/gameVideo6.png';
import GAMEVideo7 from '../../static/landing/Ecommerce/gameVideo7.png';
import GAMEVideo8 from '../../static/landing/Ecommerce/gameVideo8.png';
import GAMEVideo9 from '../../static/landing/Ecommerce/gameVideo9.png';
import ContainerBg from '../../static/pageBg.png';
import ContainerBg2 from '../../static/pageBg2.png';
import { ImageCarousel, RecommandCarousel, VideoArray } from '../Carousel/carousel';
import ApexChart from '../Chart/Radar';
import FormGroup from '../Form/FormGroup';
import ModalRefund from '../Modal/ModalRefund';
import { AI1Svg, AI2Svg, AI3Svg, Material1Svg, Material2Svg, Material3Svg, Material4Svg } from '../Svg/Landing';
import Layouts from '../Layout/index';
import { LimitSale, CardBox } from '../LimitSale';

const { Title, Text } = Typography;
const { Content } = Layout;
const ScrollOverPack = ScrollAnim.OverPack;
const Links = ScrollAnim.Link;

const Banner = Styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    text-align: center;
    padding: 50px 100px;
    background-color: rgb(0,0,0,0.2);
    width: 845px;
    @media (max-width: 768px) {
        width: 95%;
        margin: auto;
        text-align: center;
        padding: 0px 0px;
    }
    p {
        font-size: 25px;
        color: #e1e1e1;
        text-align: center;
        letter-spacing: 3px;
            @media (max-width: 768px) {
                width: 100%;
                margin: auto;
                font-size: 16px;
            }
    }
    .buttonBox {
        display: flex;
        justify-content: space-around;
        height: 70px;
        align-items: center;
    }
    button {
        padding: 10px 35px;
        font-size: 20px;
        letter-spacing: 2px;
        border-radius: 5px;
        line-height: 1.5;
        transition: all 0.2s;
        &:hover {
            padding: 15px 40px;
        }
        @media (max-width: 768px) {
            padding: 10px 10px;
            font-size: 16px;
            &:hover {
                padding: 15px 35px;
            }
        }
    }
    h1.ant-typography, .ant-typography h1 {
        @media (max-width: 768px) {
            font-size: 2rem;
        }
    }
` 
const StyledTitleBox = Styled.div`
    text-align: center;
    padding: 100px 0px 30px 0px; 
    @media (max-width: 768px) {
        padding: 60px 0px 60px 0px;
    }
    h3.ant-typography {
        margin-bottom: 30px;
        position: relative;
        &:before {
            content: " ";
            position: absolute;
            background: #00a1de;
            width: 75px;
            height: 3px;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
        }        
    }
`
const Container = Styled(Content)`
    overflow: hidden;
    padding: 0px 50px 70px;
    @media (max-width: 768px) {
        padding: 0px 10px 40px;
    }
`
const Wrapper = Styled.div`
    background: url(${ContainerBg});
    background-repeat: no-repeat;
    background-size: 100% 50%;
    background-position: top center;
    position: relative;
    overflow: hidden;
    min-height: 13015px;
    @media (max-width: 1024px) {
        background-size: 100% 1200px;
    }
    @media (max-width: 768px) {
        background-size: 100% 700px;
    }
    &:before {
        content: " ";
        position: absolute;
        background: url(${ContainerBg2});
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 50%;
        background-position: top center;
        transform: translateY(100%);
        z-index: -1;
    }
`

const TitleBox = ({ email, title, subtitle, spaceTop, children }) => {
    const { t } = useTranslation();
    switch(email) {
        case "true": return (
                <StyledTitleBox>    
                    <Title level={3} type="black">
                        {t('language.form.title')}
                    </Title>
                    <Text type="normal-color" style={{ textAlign: 'center' }}>
                        {t('language.form.subtitle')}
                    </Text>
                </StyledTitleBox>
        );
        break;
        case "false":   return (
                <StyledTitleBox  className={ spaceTop ? ("paddingTopSpace") : ("") }>
                        <Title level={3} type="black">
                            {title}
                        </Title>
                        <p type="normal-color" className="landingPageSubtitle">
                            {subtitle}
                        </p>
                </StyledTitleBox>    
        );
        break;
        default: return (
                <StyledTitleBox>
                    <TweenOne
                    animation={{ y: 0, opacity: 1, duration: 300 }}
                    key="1"
                    style={{ transform: 'translateY(100px)', opacity: 0 }}>
                        <Title level={3} type="black">
                            {title}
                        </Title>
                    </TweenOne>
                    <TweenOne
                    animation={{ y: 0, opacity: 1, duration: 300, delay: 300 }}
                    key="2"
                    style={{ transform: 'translateY(100px)', opacity: 0 }}>
                        <p type="normal-color" className="landingPageSubtitle">
                            {subtitle}
                        </p>
                    </TweenOne>
                    <TweenOne
                    animation={{ y: 0, opacity: 1, duration: 200, delay: 600 }}
                    key="3"
                    style={{ transform: 'translateY(100px)', opacity: 0 }}>
                        {children}
                    </TweenOne>
                </StyledTitleBox>  
          ) 
          break;       
    }
}
const ColImg = ({ svg, text }) => {
    return (
        <>  <div style={{ width: 230, margin: 'auto' }}>
                {svg}
            </div>
            <Title style={{ fontSize: '1rem', textAlign: 'center', marginTop: '1rem' }}>{text}</Title>
        </>
    )
} 
const ItemImg = ({ Img, title }) => {
    return (
        <>
        <div className="radarBox" >
            <div className="radarImg">
                <img src={Img} width="100%" alt="radar" />
                <Title level={4}>{title}</Title>
            <p className="more"><FontAwesomeIcon icon={faSearch} style={{ fontSize: '20px', marginRight: '10px' }}/>查看分析</p>
            </div>
        </div>
        </>
    )
}
const LandingPage = () => {
    const { onScrollEnterChange } = useContext(ScrollContext);
    const { onLandingPage } = useContext(LandingPageContext);
    const onEnterChange = (mode) => {onScrollEnterChange(mode === 'enter')}
    const [modal, setModel] = useState(false);
    const [refundModal, setRefundModal] = useState(false);
    const [kind, setKind] = useState("");
    const [currentSelect, setCurrentSelect] = useState(0);
    const [seriesData, setSeriesData] = useState([0, 0, 0, 0, 0, 0]);
    const [showPrev, setShowPrev] = useState(true);
    const [showNext, setShowNext] = useState(true);
    const [minLength, setMinLength] = useState(13);
    const [modalWidth, setModalWidth] = useState('80vw');
    const [isImageCarousel, setIsImageCarousel] = useState(true);
    const ItemsTitle = [
        {   
            "img" : EC1,
            "text":"畫面構圖佳",
            "data": [70, 76, 72, 90, 95, 92]
        },{
            "img" : EC2,
            "text": "畫面聚焦度佳",
            "data": [95, 80, 97, 72, 70, 81]
        },{
            "img": EC3,
            "text":"色彩飽和度佳",
            "data": [90, 84, 92, 98, 68, 90]
        },{ 
            "img": EC4,
            "text": "色彩使用性佳",
            "data": [92, 81, 98, 75, 80, 98]
        },{
            "img": EC5,
            "text":"畫面聚焦度佳",
            "data": [90, 90, 95, 90, 93, 75]
        },{
            "img": EC6,
            "text": "色彩使用性佳",
            "data": [83, 78, 70, 91, 87, 92]
        },{
            "img": EC7,
            "text": "色彩協調性佳",
            "data": [97, 86, 74, 92, 87, 90]
        },{ 
            "img": EC8,
            "text": "色彩使用性佳",
            "data": [98, 86, 99, 84, 88, 100]
        },{ 
            "img": EC9,
            "text": "色彩協調性佳",
            "data": [97, 92, 90, 95, 88, 88]
        },{ 
            "img": EC10,
            "text": "色彩飽和度佳",
            "data": [86, 84, 88, 90, 80, 90] 
        },{
            "img": EC11,
            "text": "色彩使用性佳",
            "data": [94, 92, 90, 91, 90, 97]
        },{
            "img": EC12,
            "text": "色彩協調性佳",
            "data": [98, 82, 94, 94, 92, 97]
        },{
            "img": EC13,
            "text": "色彩亮度／對比佳",
            "data": [78, 95, 86, 89, 81, 88]
        },{
            "img": EC14,
            "text": "色彩亮度／對比佳",
            "data": [88, 94, 90, 86, 90, 89]
        },{
            "img": EC15,
            "text": "色彩協調性佳",
            "data": [98, 92, 94, 91, 87, 95]
        },{
            "img": EC16,
            "text": "畫面聚焦度佳",
            "data": [97, 98, 99, 92, 76, 90]
        },{
            "img": EC17,
            "text": "色彩亮度／對比佳",
            "data": [88, 95, 86, 90, 88, 81]
        }]
    const BrandItemsTitle = [
            {   
                "img" : BR1,
                "text":"畫面聚焦度佳",
                "data": [94, 92, 97, 87, 78, 83]
            },{
                "img" : BR2,
                "text": "色彩使用性佳",
                "data": [83, 85, 85, 90, 86, 94]
            },{
                "img" : BR3,
                "text":"色彩飽和度佳",
                "data": [97, 93, 90, 98, 87, 92]
            },{ 
                "img" : BR4,
                "text": "色彩亮度／對比佳",
                "data": [96, 98, 90, 93, 85, 88]
            },{
                "img" : BR5,
                "text":"色彩亮度／對比佳",
                "data": [90, 95, 93, 90, 79, 83]
            },{
                "img" : BR6,
                "text": "色彩使用性佳",
                "data": [88, 90, 86, 93, 87, 97]
            },{
                "img" : BR7,
                "text": "畫面構圖佳",
                "data": [92, 86, 98, 95, 99, 96]
            },{ 
                "img" : BR8,
                "text": "色彩飽和度佳",
                "data": [93, 97, 84, 98, 91, 79]
            },{ 
                "img" : BR9,
                "text": "色彩亮度／對比佳",
                "data": [95, 97, 85, 90, 88, 92]
            },{ 
                "img" : BR10,
                "text": "色彩亮度／對比佳",
                "data": [87, 92, 88, 90, 85, 76] 
            },{
                "img" : BR11,
                "text": "色彩使用性佳",
                "data": [91, 87, 90, 81, 86, 98]
            },{
                "img" : BR12,
                "text": "色彩飽和度佳",
                "data": [98, 92, 94, 99, 90, 86]
            },{
                "img" : BR13,
                "text": "色彩飽和度佳",
                "data": [78, 86, 90, 97, 87, 86]
            },{
                "img" : BR14,
                "text": "色彩飽和度佳",
                "data": [81, 85, 88, 96, 78, 76]
        }]        
    const GameItemsTitle = [
            {   
                "img" : GAME1,
                "text":"畫面聚焦度佳",
                "data": [90, 87, 93, 81, 88, 93]
            },{
                "img" : GAME2,
                "text": "色彩亮度／對比佳",
                "data": [91, 93, 93, 87, 89, 81]
            },{
                "img" : GAME3,
                "text":"畫面聚焦度佳",
                "data": [87, 90, 91, 88, 90, 85]
            },{ 
                "img" : GAME4,
                "text": "色彩協調性佳",
                "data": [99, 87, 85, 89, 92, 73]
            },{
                "img" : GAME5,
                "text":"色彩飽和度佳",
                "data": [83, 83, 92, 99, 91, 74]
            },{
                "img" : GAME6,
                "text": "色彩使用性佳",
                "data": [93, 87, 87, 90, 90, 93]
            },{
                "img" : GAME7,
                "text": "色彩協調性佳",
                "data": [97, 92, 95, 85, 90, 87]
            },{ 
                "img" : GAME8,
                "text": "色彩使用性佳",
                "data": [87, 96, 88, 85, 90, 99]
            },{ 
                "img" : GAME9,
                "text": "畫面聚焦度佳",
                "data": [94, 76, 95, 93, 82, 79]
            },{ 
                "img" : GAME10,
                "text": "畫面構圖佳" ,
                "data": [80, 87, 97, 80, 97, 85]
            },{
                "img" : GAME11,
                "text": "色彩亮度／對比佳",
                "data": [94, 96, 87, 91, 86, 93]
            },{
                "img" : GAME12,
                "text": "色彩協調性佳",
                "data": [98, 90, 87, 78, 93, 90]
            },{
                "img" : GAME13,
                "text": "色彩協調性佳",
                "data": [98, 91, 93, 87, 83, 80]
            },{
                "img" : GAME14,
                "text": "色彩亮度／對比佳",
                "data": [93, 97, 87, 88, 88, 89]
        }]
    const ECVideoItemsTitle = [
        {   
            "img" : ECVideo1,
            "text":"特寫強調佳",
            "data": [80, 99, 99, 84, 99, 99, 82, 75]
        },{
            "img" : ECVideo2,
            "text": "特寫強調佳",
            "data": [75, 99, 83, 78, 99, 99, 75, 75]
        },{
            "img" : ECVideo3,
            "text":"文字資訊佳",
            "data": [85, 85, 89, 82, 99, 99, 87, 99]
        },{ 
            "img" : ECVideo4,
            "text": "字卡比例佳",
            "data": [78, 83, 99, 83, 99, 99, 84, 81]
        },{
            "img" : ECVideo5,
            "text":"畫面配速佳",
            "data": [75, 93, 99, 75, 99, 99, 78, 75]
        },{
            "img" : ECVideo6,
            "text":"字卡比例佳",
            "data": [78, 80, 99, 70, 99, 99, 83, 75]
        },{
            "img" : ECVideo7,
            "text":"音樂效果佳",
            "data": [83, 84, 99, 78, 94, 92, 81, 99]
        },{
            "img" : ECVideo8,
            "text":"畫面配速佳",
            "data": [83, 84, 99, 78, 94, 92, 81, 99]
        }]
    const BRVideoItemsTitle = [
        {   
            "img" : BRVideo1,
            "text":"特寫強調佳",
            "data": [92, 99, 99, 83, 99, 99, 80, 99]
        },{
            "img" : BRVideo2,
            "text": "字卡比例佳",
            "data": [80, 82, 83, 81, 93, 95, 86, 75]
        },{
            "img" : BRVideo3,
            "text":"畫面配速佳",
            "data": [78, 75, 97, 83, 96, 97, 79, 75]
        },{ 
            "img" : BRVideo4,
            "text": "文字資訊佳",
            "data": [75, 82, 91, 76, 97, 97, 80, 75]
        },{
            "img" : BRVideo5,
            "text":"畫面配速佳",
            "data": [75, 79, 94, 75, 94, 94, 76, 80]
        },{
            "img" : BRVideo6,
            "text":"畫面配速佳",
            "data": [72, 99, 97, 76, 99, 99, 80, 75]
        },{
            "img" : BRVideo7,
            "text":"文字資訊佳",
            "data": [70, 90, 99, 71, 99, 99, 79, 75]
        },{
            "img" : BRVideo8,
            "text":"畫面配速佳",
            "data": [90, 95, 99, 84, 98, 97, 77, 70]
        }]
    const GAMEVideoItemsTitle = [{   
        "img" : GAMEVideo1,
        "text":"字卡比例佳",
        "data": [90, 86, 93, 83, 99, 99, 76, 82]
    },{
        "img" : GAMEVideo2,
        "text": "畫面配速佳",
        "data": [79, 80, 99, 75, 93, 94, 81, 86]
    },{
        "img" : GAMEVideo3,
        "text":"畫面配速佳",
        "data": [86, 90, 99, 82, 94, 95, 87, 81]
    },{ 
        "img" : GAMEVideo4,
        "text": "音樂效果佳",
        "data": [87, 88, 91, 90, 93, 93, 90, 94]
    },{
        "img" : GAMEVideo5,
        "text":"文字資訊佳",
        "data": [86, 80, 97, 84, 99, 87, 90, 97]
    },{
        "img" : GAMEVideo6,
        "text":"音樂效果佳",
        "data": [75, 78, 92, 83, 94, 93, 80, 99]
    },{
        "img" : GAMEVideo7,
        "text": "畫面配速佳",
        "data": [83, 92, 99, 78, 94, 92, 81, 95]
    },{
        "img" : GAMEVideo8,
        "text":"音樂效果佳",
        "data": [90, 94, 95, 88, 93, 87, 92, 95]
        }]    
    const prev = () => {
        setCurrentSelect(currentSelect - 1);
    }
    const next = () => {
        setCurrentSelect(currentSelect + 1);
    }
    const updateCurrentSlide = (index) => {
        if (currentSelect !== index) {
            setCurrentSelect(index);
        }       
    };
    const handleClick = (chioce, select) => {
        setModel(true);
        setKind(chioce);
        setCurrentSelect(select);
        switch(chioce) {
            case "EC":
                setSeriesData(ItemsTitle[select].data);
                setMinLength(ItemsTitle.length - 1);
            break;
            case "BR":
                setSeriesData(BrandItemsTitle[select].data);
                setMinLength(BrandItemsTitle.length - 1);                 
            break;
            case "GAME":
                setSeriesData(GameItemsTitle[select].data);
                setMinLength(GameItemsTitle.length - 1);                 
            break;
            case "BRVideo":
                setSeriesData(BRVideoItemsTitle[select].data);
                setMinLength(BRVideoItemsTitle.length - 1);
            break;
            case "GAMEVideo": 
                setSeriesData(GAMEVideoItemsTitle[select].data);
                setMinLength(GAMEVideoItemsTitle.length - 1);
            break;
            case "ECVideo":
                setSeriesData(ECVideoItemsTitle[select].data);
                setMinLength(ECVideoItemsTitle.length - 1);                 
            break;
            default:
                setSeriesData(null);
                setMinLength(null);
            break;
        }
    }
    const CelebrityRecommendation = () => {
        return (
        <>
            <Row style={{ margin: '1em 0px 8em 0px' }}>
            <Col lg={0} xl={2}></Col>
            <Col lg={24} xl={20}>
                <Card style={{  boxShadow: '0 2px 8px #f0f1f2' }}>
                    <Row style={{ alignItems: 'center', justifyContent: 'center' }} className="celebrityBox">
                        <Col lg={24} xl={8}>
                            <div className="celebrityPic">
                                <img src={Celebrity1} width="100%" alt="王俊人(CJ)"/>
                            </div>
                            <Title level={3} style={{ margin: '0', textAlign: 'center' }}>王俊人(CJ)</Title>
                            <div style={{ margin: '0', textAlign: 'center' }}><Text style={{  fontWeight: '500',color: '#262626' }}>SoWork創辦人<br />前奧美互動行銷副總經理</Text></div>
                        </Col>
                        <Col lg={24} xl={16} className="celebrityContextBox">
                            <Title level={4} style={{ textAlign: 'justify', marginTop: '0.5em' }}>
                            AdHero 是一個很不同的廣告素材監測平台，協助我們蒐集廣告數據，幫我們評斷 CPC 該設定標準、廣告素材呈現方法。
                            </Title>
                            <p>很開心能有機會試用到這個產品，有幾個特點，是我想分享的：</p>
                            <p><FontAwesomeIcon icon={faCommentDots} style={{ fontSize: '20px', marginRight: '10px' }}/>紀錄每天市場均值變化，有對照組作為成效好壞的依據。</p>
                            <p><FontAwesomeIcon icon={faCommentDots} style={{ fontSize: '20px', marginRight: '10px' }}/>圖片素材分析，了解畫面構圖、色彩對比等是否有調整空間。</p>
                            <p><FontAwesomeIcon icon={faCommentDots} style={{ fontSize: '20px', marginRight: '10px' }}/>影片素材分析，知道字卡比例、音樂效果是否合適。</p>
                            <p><FontAwesomeIcon icon={faCommentDots} style={{ fontSize: '20px', marginRight: '10px' }}/>今日Top100素材，追蹤市場成效好的共通點。</p>
                            <p style={{ marginBottom: '0px', textAlign: 'justify' }}>看得出來，在數據蒐集以及素材分析上他們投入了大量心力與時間彙整，但數據蒐集無邊無際，建議各位使用前，還是要跟AdHero確認好數據來源。而素材判斷標準，AdHero有一套自己的方式，或許你的觀點不同，也可以跟他們多多交流。</p>
                            <p>不知道該如何衡量廣告成效時，不妨試試這個工具吧！</p>
                        </Col>
                    </Row>
                </Card>                            
            </Col>
            <Col lg={0} xl={2}></Col>
            </Row>
            <Row>
            <Col lg={0} xl={2}></Col>
            <Col lg={24} xl={20}>
                <Card style={{  boxShadow: '0 2px 8px #f0f1f2' }} className="celebrityContextBox2">
                    <Row style={{ alignItems: 'center', justifyContent: 'center' }} className="celebrityBoxReverse">
                        <Col lg={24} xl={16} className="celebrityContextBoxInside">
                        <Title level={4} style={{ textAlign: 'justify', marginTop: '0.5em' }}>AdHero協助你優化廣告素材， 行銷初心者的好選擇！</Title>
                            <p>『廣告素材』是一個同時需要設計/美術專業、行銷專業才能優化好的工作項目，尤其對於剛開始學廣告優化的新手行銷人來說，因為剛開始學習廣告優化、再加上行銷人在本質上並沒有美術/設計專業，導致很難審視廣告素材哪裡設計有問題，AdHero工具在廣告素材上提供了不同角度的協助，透過系統從色彩、構圖、聚焦度等不同的角度來解析廣告素材，再搭配素材的資料庫給行銷人參考，對新手來說有一定程度的幫助，除此之外，報表內的數據以及功能更是在使用上非常簡單、容易上手，是新手的好選擇！</p>
                        </Col>
                        <Col lg={24} xl={8}>
                            <div className="celebrityPic">
                                <img src={Celebrity2} width="100%" alt="Harris先生"/>
                            </div>
                            <Title level={3} style={{ margin: '0', textAlign: 'center' }}>Harris先生</Title>
                            <div style={{ margin: '0', textAlign: 'center' }}><Text style={{  fontWeight: '500',color: '#262626' }}>數位行銷優化專家</Text></div>
                        </Col>
                    </Row>
                </Card>                            
            </Col>
            <Col lg={0} xl={2}></Col>
            </Row>            
        </>
        )
    }
    const KindCarousel = () => {
        return (
                <>
                <TitleBox 
                    spaceTop
                    email="false"
                    title={`${isImageCarousel ? ("圖片結構分析") : ("影片觀感預測分析") }`}
                    subtitle="含有廣告成效因子的 AI 分析模型，精準透析素材結構。" />  
                    <Row gutter={{ lg: 32 }} className="carouselModalspace">
                        <Col lg={24} xl={12}>
                        {isImageCarousel ? 
                        (<ImageCarousel select={currentSelect} onChange={updateCurrentSlide} species={kind} />) : 
                        (<VideoArray currentVideo={kind} index={currentSelect}/>)}
                        </Col>
                        <Col lg={24} xl={12} id="radarOuter" className="radarOuter">
                            <ApexChart  stateType={isImageCarousel} seriesData={seriesData}/>
                        </Col>
                    </Row>
                    <Row justify="center">
                            { showPrev ? (<Button type="primary" onClick={prev} style={{ margin: '15px' }} className="primaryCarouselButton" ghost>
                                <FontAwesomeIcon icon={faAngleLeft} style={{ fontSize: '20px', marginRight: '10px' }}/> 上一張
                            </Button>) : (<div />) } 
                            { showNext ? (<Button type="primary" onClick={next} style={{ margin: '15px' }} className="primaryCarouselButton" ghost>
                                下一張 <FontAwesomeIcon icon={faAngleRight} style={{ fontSize: '20px', marginLeft: '10px' }}/>
                            </Button>) : (<div />) } 
                    </Row>
                    <div style={{ textAlign: 'center' }}>
                        <Button type="primary" style={{ margin: '0px 0px 30px 0px' }} className="primaryHover">
                            <a className="LP_Banner_EC_Next" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faImages} style={{ fontSize: '20px', marginRight: '10px' }}/> 查看廣告圖片調整建議
                            </a>
                        </Button>
                    </div>
                </>
        )
    }
    useEffect(() => {
        onLandingPage(true);
        if (window.innerWidth >= 768) {
            setModalWidth('90vw');
        }
        if (window.innerWidth < 768) {
            setModalWidth('90vw');
            if (modal === true) {
                setTimeout(() => {
                    const raderWidth = document.getElementById('radarOuter').getBoundingClientRect().width;
                    const left = (400 - raderWidth)/2;
                    document.getElementById('radarOuter').scrollLeft += left;                 
                },1000
              );          
            }
        }
        if (currentSelect <= 0 ) {
            setCurrentSelect(0);
            setShowPrev(false);
        } else {
            setShowPrev(true);
        }
        if (currentSelect >= minLength) {
            setCurrentSelect(minLength);
            setShowNext(false);
        } else {
            setShowNext(true);
        }
        switch(kind) {
            case "EC":
                setIsImageCarousel(true);
                setSeriesData(ItemsTitle[currentSelect].data);
            break;
            case "BR":
                setIsImageCarousel(true);
                setSeriesData(BrandItemsTitle[currentSelect].data);                 
            break;
            case "GAME":
                setIsImageCarousel(true);
                setSeriesData(GameItemsTitle[currentSelect].data);                 
            break;
            case "BRVideo":
                setIsImageCarousel(false);
                setSeriesData(BRVideoItemsTitle[currentSelect].data);
            break;
            case "GAMEVideo": 
                setIsImageCarousel(false);
                setSeriesData(GAMEVideoItemsTitle[currentSelect].data);
            break;
            case "ECVideo":
                setIsImageCarousel(false);
                setSeriesData(ECVideoItemsTitle[currentSelect].data);                
            break;
            default:
                setIsImageCarousel(null);
                setSeriesData(null);
            break;
        }
    },[currentSelect, kind])
    return(
        <Layouts>
        <LimitSale /> 
        <Modal
            title=" "
            centered
            width={modalWidth}
            footer={null}
            visible={modal}
            onCancel={() => setModel(false)}
          >
            <KindCarousel />
        </Modal>
        <ModalRefund refundModal={refundModal} setRefundModal={() => setRefundModal(false)}/>
        <ScrollOverPack
        always={false}
        id="page0"
        key="1">
            <div className="video-container">
            <ReactPlayer
                loop={true}
                muted={true}
                playing={true}
                controls={false}
                playsinline={true}
                className='react-player'
                url={[
                    {src: Mp4Desktop, type: 'video/mp4'}
                  ]}
                width='100%'
                height='100%'
            />
            <Banner>
                <TweenOne
                animation={{ y: 0, opacity: 1, duration: 500 }}
                key="1"
                style={{ transform: 'translateY(-100px)', opacity: 0 }}>
                    <Title type="warning">
                        給你源源不絕的廣告設計靈感
                    </Title>
                </TweenOne>
                <TweenOne
                animation={{ y: 0, opacity: 1, duration: 500, delay: 500 }}
                key="2"
                style={{ transform: 'translateY(-100px)', opacity: 0 }}>
                    <p>
                    百大素材分析，透析市場最新趨勢
                    </p>
                </TweenOne>
                <div className="buttonBox">
                    <TweenOne
                    animation={{ y: 0, opacity: 1, duration: 300, delay: 1500 }}
                    key="4"
                    style={{ transform: 'translateY(100px)', opacity: 0 }}>
                        <Links to="page3"><Button type="primary" size="default">電商廣告</Button></Links>
                    </TweenOne>
                    <TweenOne
                    animation={{ y: 0, opacity: 1, duration: 300, delay: 1800 }}
                    key="5"
                    style={{ transform: 'translateY(100px)', opacity: 0 }}>
                        <Links to="page4"><Button type="primary" size="default">品牌廣告</Button></Links>
                    </TweenOne>
                    <TweenOne
                    animation={{ y: 0, opacity: 1, duration: 300, delay: 2100 }}
                    key="6"
                    style={{ transform: 'translateY(100px)', opacity: 0 }}>
                        <Links to="page5"><Button type="primary" size="default">遊戲廣告</Button></Links>
                    </TweenOne>
                </div>
            </Banner>
            <Links to="page1"><div className="scrollPointer"></div></Links>
            </div>
        </ScrollOverPack>
        <ScrollElement
        id="page1"
        onChange={({ mode }) => onEnterChange(mode)}
        playScale={0.1}>
            <Wrapper section1={true}>
            <ScrollOverPack
            always={false}
            id="page2"
            key="2"> 
                <Container>  
                    <TitleBox email="false" 
                    title="市場素材盡收眼底"
                    subtitle="每日不間斷的收集市場廣告素材，提供最即時的素材製作動向，並透過Adhero 人工智慧分析重要成效指標，讓你一指掌握最新的設計趨勢！" />     
                       <Row justify="center" align="middle">
                        <Col sm={24} lg={6} style={{ paddingBottom: 20 }}>
                            <TweenOne
                            animation={{ y: 0, opacity: 1, duration: 300, delay: 600 }}
                            key="1"
                            style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                <ColImg svg={<Material1Svg />} text="提供多種產業類型" />
                            </TweenOne>
                        </Col>
                        <Col sm={24} lg={6} style={{ paddingBottom: 20 }}>
                            <TweenOne
                            animation={{ y: 0, opacity: 1, duration: 300, delay: 900 }}
                            key="2"
                            style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                <ColImg svg={<Material2Svg />} text="收錄最多優質廣告素材" />
                            </TweenOne>
                        </Col>
                        <Col sm={24} lg={6} style={{ paddingBottom: 20 }}>
                            <TweenOne
                            animation={{ y: 0, opacity: 1, duration: 300, delay: 1200 }}
                            key="3"
                            style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                <ColImg svg={<Material4Svg />} text="任意挑選日期區間" />
                            </TweenOne>
                        </Col>
                        <Col sm={24} lg={6} style={{ paddingBottom: 20 }}>
                            <TweenOne
                            animation={{ y: 0, opacity: 1, duration: 300, delay: 1500 }}
                            key="4"
                            style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                <ColImg svg={<Material3Svg />} text="追蹤指定品牌" />
                            </TweenOne>
                        </Col>
                    </Row>        
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page3"
            key="3">
                <Container>
                <TitleBox 
                    title="電商廣告圖片"
                    subtitle="涵蓋PChome、momo、生活市集、蝦皮商城等知名電商">
                    <Button type="primary" style={{ margin: '30px 0px' }} className="primaryHover">   
                        <a className="LP_Banner_EC_100000" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faImages} style={{ fontSize: '18px', marginRight: '10px' }}/>查看其他 100,000+ 張電商圖片
                        </a>
                    </Button>
                </TitleBox>
                    <Row gutter={{ lg: 32 }}  justify="center">
                        {ItemsTitle.map((item, index) => 
                            <Col sm={24} lg={8} key={index} onClick={() => handleClick("EC", index)}>
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 600 + (index*300) }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={item.img} title={item.text}/>
                                </TweenOne>
                            </Col>
                        )}
                            <Col sm={24} lg={8}>
                                <a className="LP_Banner_EC_More" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial"  rel="noopener noreferrer" target="_blank">
                                    <TweenOne
                                    animation={{ y: 0, opacity: 1, duration: 300, delay: 6000}}
                                    key="1"
                                    style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                            <ItemImg Img={EC18} title="查看更多廣告素材"/>
                                    </TweenOne>
                                </a>
                            </Col>                        
                    </Row>           
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page4"
            key="4">
                <Container>
                <TitleBox 
                    title="品牌廣告圖片"
                    subtitle="包含食品、美妝、服飾、文藝、服務等其他多種產業分類">
                        <Button type="primary" style={{ margin: '30px 0px' }} className="primaryHover">
                            <a className="LP_Banner_Brand_100000" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faImages} style={{ fontSize: '18px', marginRight: '10px' }}/>查看其他 50,000+ 張品牌圖片
                            </a>
                        </Button>
                    </TitleBox>
                    <Row gutter={{ lg: 32 }}  justify="center">
                        {BrandItemsTitle.map((item, index) => 
                            <Col sm={24} lg={8} key={index} onClick={() => handleClick("BR", index)}>
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 600 + (index*300) }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={item.img} title={item.text}/>
                                </TweenOne>
                            </Col>
                        )}
                            <Col sm={24} lg={8}>
                            <a className="LP_Banner_Brand_More" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" rel="noopener noreferrer" target="_blank">
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 4800 }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={BR15} title="查看更多廣告素材"/>
                                </TweenOne>
                                </a>
                            </Col>
                    </Row>           
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page5"
            key="5">
                <Container>
                <TitleBox 
                    title="遊戲廣告圖片"
                    subtitle="網羅RPG、SCG、SLG、MMORPG等其他多種遊戲類型">
                            <Button type="primary" style={{ margin: '30px 0px' }} className="primaryHover" >
                                <a className="LP_Banner_Game_100000" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faImages} style={{ fontSize: '18px', marginRight: '10px' }}/>查看其他 30,000+ 張遊戲圖片</a>
                            </Button>
                    </TitleBox>
                    <Row gutter={{ lg: 32 }}  justify="center">
                        {GameItemsTitle.map((item, index) => 
                            <Col sm={24} lg={8} key={index} onClick={() => handleClick("GAME", index)}>
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 600 + (index*300) }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={item.img} title={item.text}/>
                                </TweenOne>
                            </Col>
                        )}
                            <Col sm={24} lg={8}>
                            <a className="LP_Banner_Game_More" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" rel="noopener noreferrer" target="_blank">
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 5100 }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={Game15} title="查看更多廣告素材"/>
                                </TweenOne>
                                </a>
                            </Col>
                    </Row>           
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page6"
            key="6">
                <Container>
                <TitleBox email="false" 
                    title="AdHero AI 分析技術優勢"
                    subtitle="" />     
                       <Row gutter={{ lg: 32 }}>
                        <Col sm={24} lg={8}>
                            <TweenOne
                            animation={{ y: 0, opacity: 1, duration: 300, delay: 600 }}
                            key="1"
                            style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                <ColImg svg={<AI1Svg />} text="類神經網絡智能系統" />
                                <p type="normal-color" style={{ textAlign: 'center' }}>結合領域內最新演算法，即時優化AdHero圖像／影像分析指標。</p>
                            </TweenOne>
                        </Col>
                        <Col sm={24} lg={8}>
                            <TweenOne
                            animation={{ y: 0, opacity: 1, duration: 300, delay: 900 }}
                            key="2"
                            style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                <ColImg svg={<AI2Svg />} text="結構化素材標籤資料庫" />
                                <p type="normal-color" style={{ textAlign: 'center' }}>基於 Google 圖像／影像分析技術，累積巨量素材打造標籤資料庫。</p>
                            </TweenOne>
                        </Col>
                        <Col sm={24} lg={8}>
                            <TweenOne
                            animation={{ y: 0, opacity: 1, duration: 300, delay: 1200 }}
                            key="3"
                            style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                <ColImg svg={<AI3Svg />} text="智能優化學習分析" />
                                <p type="normal-color" style={{ textAlign: 'center' }}>透過獨家AI模型，自動分析廣告素材，以圖像化方式記錄市場分析結果，提供明確的製作優化方向。</p>
                            </TweenOne>
                        </Col>
                    </Row>                                 
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page7"
            key="7">
                <Container>
                <TitleBox space={true} className="test" email="false" 
                    title="AI獨家影片分析模型"
                    subtitle="以 Google 影像分析技術為基底，同時結合獨家研發演算法優化模型，並使用大量影片及投放成效結果做為訓練資料，能辨識出「影片畫面片段中所包含元素」與「觀眾感受」之間的關係，建立完整評斷基準，找出整體市場中的優質影片。" />                                    
                        <TweenOne
                            animation={{ y: 0, opacity: 1, duration: 300, delay: 600 }}
                            key="1"
                            style={{ transform: 'translateY(100px)', opacity: 0 }}>
                        <div className="AIvideoContainer">
                            <ReactPlayer
                                loop={true}
                                muted
                                playing
                                controls={false}
                                url={AIvideo}
                                width='100%'
                                height='100%'
                            />                
                        </div>
                        </TweenOne>                 
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page8"
            key="8">
                <Container>
                <TitleBox 
                    title="電商廣告影片"
                    subtitle="涵蓋PChome、momo、生活市集、蝦皮商城等知名電商">  
                    <Button type="primary" style={{ margin: '30px 0px' }} className="primaryHover">
                        <a className="LP_Video_EC_10000" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faImages} style={{ fontSize: '18px', marginRight: '10px' }}/>查看其他 10,000+ 部電商影片
                        </a>
                    </Button>
                    </TitleBox>
                    <Row gutter={{ lg: 32 }}  justify="center">
                        {ECVideoItemsTitle.map((item, index) => 
                            <Col sm={24} lg={8} key={index} onClick={() => handleClick("ECVideo", index)}>
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 600 + (index*300) }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={item.img} title={item.text}/>
                                </TweenOne>
                            </Col>
                        )}    
                            <Col sm={24} lg={8}>
                            <a className="LP_Video_EC_More" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" rel="noopener noreferrer" target="_blank">
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 2100 }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={ECVideo9} title="查看更多廣告素材"/>
                                </TweenOne>
                            </a>
                            </Col>                                  
                    </Row>      
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page9"
            key="9">
                <Container>
                <TitleBox 
                    title="品牌廣告影片"
                    subtitle="包含食品、美妝、服飾、文藝、服務等其他多種產業分類">  
                    <Button type="primary" style={{ margin: '30px 0px' }} className="primaryHover">
                        <a className="LP_Video_Brand_10000" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faImages} style={{ fontSize: '18px', marginRight: '10px' }}/>查看其他 10,000+ 張品牌影片
                        </a>
                    </Button>
                </TitleBox>
                    <Row gutter={{ lg: 32 }}  justify="center">
                        {BRVideoItemsTitle.map((item, index) => 
                            <Col sm={24} lg={8} key={index} onClick={() => handleClick("BRVideo", index)}>
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 600 + (index*300) }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={item.img} title={item.text}/>
                                </TweenOne>
                            </Col>
                        )}     
                            <Col sm={24} lg={8}>
                            <a className="LP_Video_Brand_More" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" rel="noopener noreferrer" target="_blank">
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay:1800 }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={BRVideo9} title="查看更多廣告素材"/>
                                </TweenOne>
                                </a>
                            </Col>         
                    </Row>      
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page10"
            key="10">
                <Container>
                <TitleBox 
                    title="遊戲廣告影片"
                    subtitle="網羅RPG、SCG、SLG、MMORPG等其他多種遊戲類型">  
                    <Button type="primary" style={{ margin: '30px 0px' }} className="primaryHover">
                        <a className="LP_Video_Game_20000" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faImages} style={{ fontSize: '18px', marginRight: '10px' }}/>查看其他 20,000+ 張遊戲影片
                        </a>
                    </Button>
                        
                </TitleBox>
                    <Row gutter={{ lg: 32 }} justify="center">
                        {GAMEVideoItemsTitle.map((item, index) => 
                            <Col sm={24} lg={8} key={index} onClick={() =>handleClick("GAMEVideo", index)}>
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 600 + (index*300) }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                    <ItemImg Img={item.img} title={item.text}/>
                                </TweenOne>
                            </Col>
                        )}    
                            <Col sm={24} lg={8}>
                            <a className="LP_Video_Game_More" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" rel="noopener noreferrer" target="_blank">
                                <TweenOne
                                animation={{ y: 0, opacity: 1, duration: 300, delay: 2700 }}
                                key="1"
                                style={{ transform: 'translateY(100px)', opacity: 0 }}>
                                   <ItemImg Img={GAMEVideo9} title="查看更多廣告素材"/>
                                </TweenOne>
                                </a>
                            </Col>          
                    </Row>      
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page11"
            key="11">
                <Container>
                <TitleBox 
                    email="false"
                    title="最佳廣告工具"
                    subtitle="透過AdHero市場素材服務，協助您輕鬆掌握廣告趨勢" />  
                    <Row gutter={{ lg: 32 }} className="landingStrategy">
                        <Col lg={24} xl={12}>
                            <TweenOne
                                animation={{ x: 0, opacity: 1, duration: 300, delay: 600 }}
                                key="1"
                                style={{ transform: 'translateX(-100px)', opacity: 0 }}>
                                    <img src={AD1} width="100%" alt="adHero" />
                            </TweenOne>
                        </Col> 
                        <Col lg={24} xl={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <TweenOne
                                animation={{ x: 0, opacity: 1, duration: 300, delay: 600 }}
                                key="2"
                                style={{ transform: 'translateX(100px)', opacity: 0 }}>
                                   <Title level={3}>市場行銷人員</Title>
                                   <p style={{ textAlign: 'justify' }}>AdHero蒐集時下熱門市場素材，融入有獨家AI素材分析模型，憑藉海量數據累積，每日分析構圖掌握設計趨勢，並透過六大設計指標與美術人員快速溝通素材設計概念，降低人力與時間雙重成本。今天的你對於素材風格零想法嗎? AdHero給你用不完的創意巧思！</p>
                            </TweenOne>
                        </Col>
                    </Row>
                    <Row gutter={{ lg: 32 }} className="landingStrategy">
                        <Col lg={24} xl={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <TweenOne
                                animation={{ x: 0, opacity: 1, duration: 300, delay: 900 }}
                                key="3"
                                style={{ transform: 'translateX(-100px)', opacity: 0 }}>
                                   <Title level={3}>視覺設計人員</Title>
                                   <p style={{ textAlign: 'justify' }}>掌握時下最新熱門且凸顯廣告成效優異的各類素材設計風格，針對各種類型客戶的概念需求，能快速且多樣化地協助創作者利用細分不同的產業特性，針對地方特色、傳統文化、個性時尚及流行色彩等眾多不同風格及其背景類型、以相似受眾的特性，不僅僅單純提供設計創作者快速激發創意發想，更能有效提昇引發優化素材之設計靈感。</p>
                            </TweenOne>
                        </Col> 
                        <Col lg={24} xl={12} >
                            <TweenOne
                                animation={{ x: 0, opacity: 1, duration: 300, delay: 900 }}
                                key="4"
                                style={{ transform: 'translateX(100px)', opacity: 0 }}>
                                    <img src={AD2} width="100%" alt="視覺設計人員"/>
                            </TweenOne>
                        </Col>
                    </Row>
                    <Row gutter={{ lg: 32 }} className="landingStrategy">
                        <Col lg={24} xl={12} >
                            <TweenOne
                                animation={{ x: 0, opacity: 1, duration: 300, delay: 1200 }}
                                key="5"
                                style={{ transform: 'translateX(-100px)', opacity: 0 }}>
                                    <img src={AD3} width="100%" alt="adHero" />
                            </TweenOne>
                        </Col> 
                        <Col lg={24} xl={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <TweenOne
                                animation={{ x: 0, opacity: 1, duration: 300, delay: 1200 }}
                                key="6"
                                style={{ transform: 'translateX(100px)', opacity: 0 }}>
                                    <Title level={3}>廣告業務人員</Title>
                                   <p style={{ textAlign: 'justify' }}>經由Adhero每日即時提供百大熱門素材，身為廣告頂尖業務，讓我們幫您在第一時間掌握市場資訊，面對客戶時，專業快速地提出競品素材，輕鬆挑選出市場最夯最熱門的素材風格，進而輔助客戶了解市場狀況，與客戶共同發想出產品的獨特性，創造雙贏模式。</p>
                            </TweenOne>
                        </Col>                 
                    </Row>      
                </Container>
            </ScrollOverPack>            
            <ScrollOverPack
            always={false}
            id="page14"
            key="14">
                <Container>
                    <TitleBox 
                        email="false"
                        title="業界名人誠摯推薦"
                        subtitle="Be A Hero Today！" />
                    <CelebrityRecommendation />
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page12"
            key="12">
                <Container>
                <TitleBox 
                    email="false"
                    title="眾多使用者一致推薦"
                    subtitle="AdHero 與您一起締造成功" />
                    <RecommandCarousel />       
                </Container>
            </ScrollOverPack>
            <ScrollOverPack
            always={false}
            id="page13"
            key="13">
                <Container className="landingSalePlan">
                <TweenOne
                animation={{ y: 0, opacity: 1, duration: 300, delay: 600  }}
                key="1" style={{ transform: 'translateY(100px)', opacity: 0, textAlign: 'center' }}>
                    <Title level={2} style={{ color: 'rgb(0 161 222)' }}>嘿！我們現在有超優惠的方案！</Title>
                    <Title level={3} style={{ color: 'rgb(0 161 222)' }}>首購享七天內鑑賞期退款</Title>
                    <TitleBox style={{ padding: '20px 0px 100px 0px' }}
                    email="false"
                    spaceTop
                    title="超優惠限時方案"
                    subtitle="超優惠的方案價格，錯過可能不會再有下次囉！" />
                      <Row className="landingPageLimitPlan">
                        <Col lg={24} xl={12} className="limitSaleCard">
                          <CardBox 
                          title="市場素材方案" 
                          price="$2,999" 
                          list1="Top 100 廣告影片" 
                          list2="Top 100 廣告圖片"
                          list3="競品素材追蹤　　">
                            <Button className="limitSaleButtom" type="primary">
                                <a className="LP_Purchase_BrandEC" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" rel="noopener noreferrer" target="_blank">
                                    立即體驗
                                </a>
                            </Button>
                          </CardBox>
                        </Col>
                      </Row>
                       <Button type="primary" style={{ margin: '30px 0px',padding: '10px 30px' }}>
                           <a className="LP_Others" href="https://platform.goadhero.com/Home/Registered?Source=AdHeroMaterial" target="_blank" rel="noopener noreferrer">
                            查看其他方案
                            </a>
                        </Button>
                      <div><Button type="danger" className="refundText" onClick={() => setRefundModal(true)}>立即試用享7天鑑賞期，不滿意全額退費！</Button></div>                  
                </TweenOne>                   
                </Container>
            </ScrollOverPack>
            </Wrapper>
        </ScrollElement> 
        <Container style={{ background: '#f7f9fc' }}>  
                <TitleBox email="true" />  
                <FormGroup />
        </Container>   
        </Layouts>
    )
}

export default LandingPage;
