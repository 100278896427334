import React, { useState } from 'react';

export const CurrentLocalContext = React.createContext({
    isCurrent: '',
    changeRoute: () => {}
})

const CurrentLocalProvider = ({ children }) => {
    const [isCurrent, setIsCurrent] = useState('index');
    const changeRoute = (Local) => {
        setIsCurrent(Local);
    }
    return (
        <CurrentLocalContext.Provider value={{ isCurrent: isCurrent,
        changeRoute }}>
            {children}
        </CurrentLocalContext.Provider>
    )
}

export default CurrentLocalProvider;